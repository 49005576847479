import { CommonModule } from '@angular/common';
import { Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CnetNgxUiModule } from '@cnet/ngx-ui';

@Component({
  selector: 'app-sub-header',
  standalone: true,
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
  imports: [CommonModule, CnetNgxUiModule, RouterModule]
})
export class SubHeaderComponent implements OnInit {

  @HostBinding('style.border-bottom') public borderSize: string;
  @Input() subHeaderType: 'default' | 'info' = 'default';
  @Input() headerTitle: string = '';
  @Input() returnUrl: string;
  @Input() queryParams: any = null;
  @Input() border: boolean = true;
  isMobile = false;
  ngOnInit(): void {
    if (this.border) {
      this.borderSize = '2px solid #dbe3ea';
    } else {
      this.borderSize = '0';
    }
    this.resolveResize({ target: window });
  }


  @HostListener('window:resize', ['$event'])
  resolveResize(event) {
    this.isMobile = event.target.innerWidth <= 992
  }
}
