<form [formGroup]="filterFormGroup" *ngIf="filterFormGroup">
  <div class="row row-dates">
    <cnet-form-field class="col-md-6 col-sm-12" *ngIf="filterFormGroup.controls.evaluationDeadlineStartDate.enabled">
      <cnet-field-label>{{'Data do prazo de resposta' | translate}}</cnet-field-label>
      <input [cnetDatepickerInput]="evaluationDeadlineStartDate" formControlName="evaluationDeadlineStartDate"
        id="evaluationDeadlineStartDate" name="evaluationDeadlineStartDate" form type="text" />
      <cnet-datepicker #evaluationDeadlineStartDate></cnet-datepicker>
    </cnet-form-field>
    <div>
      {{'até' | translate}}
    </div>
    <cnet-form-field class="col-md-6 col-sm-12" *ngIf="filterFormGroup.controls.evaluationDeadlineEndDate.enabled">
      <input [cnetDatepickerInput]="evaluationDeadlineEndDate" formControlName="evaluationDeadlineEndDate"
        id="evaluationDeadlineEndDate" name="evaluationDeadlineEndDate" form type="text" />
      <cnet-datepicker #evaluationDeadlineEndDate></cnet-datepicker>
    </cnet-form-field>
  </div>
  <div class="row">
    <cnet-form-field class="col-md-6 col-sm-12" *ngIf="filterFormGroup.controls.approved.enabled">
      <cnet-field-label>{{'Resultado Final' | translate}}</cnet-field-label>
      <cnet-select formControlName="approved" id="approved" name="approved">
        <cnet-select-option id="answer-approved" [value]="true" [viewValue]="'Aprovado' | translate"></cnet-select-option>
        <cnet-select-option id="answer-disapproved" [value]="false" [viewValue]="'Reprovado' | translate"></cnet-select-option>
      </cnet-select>
    </cnet-form-field>
  </div>
</form>
