import { Component } from '@angular/core';

@Component({
  template: `
    <app-error-details
      title="{{'Você não possui permissão para acessar esta página.' | translate}}"
      description="{{'Talvez você queira fazer login em outra conta. Se você acha que existe algum problema, comunique-nos.' | translate}}">
    </app-error-details>
  `,
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {
  title = 'Oops! Esta página não existe.';
  description = 'Não conseguimos encontrar a página que você estava buscando. Se você encontrou algum problema, comunique-nos.';
}
