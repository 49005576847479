import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CnetEvaluationResult } from 'src/app/core/models/cnet-evaluation-result';
import { CnetPagedList } from 'src/app/core/models/cnet-paged-list';
import { EvaluationFilterComponent } from 'src/app/shared/components/filters/evaluation-filter/evaluation-filter.component';
import { InstitutionFilterComponent } from 'src/app/shared/components/filters/institution-filter/institution-filter.component';
import { enumInternshipFilter } from 'src/app/shared/components/filters/internship-filter/internship-filter-type';
import { InternshipFilterComponent } from 'src/app/shared/components/filters/internship-filter/internship-filter.component';
import { EvaluationService } from '../../../evaluation.service';

@Component({
  selector: 'app-evaluation-monitor',
  templateUrl: './evaluation-monitor.component.html',
  styleUrls: ['./evaluation-monitor.component.scss']
})
export class EvaluationMonitorComponent implements OnInit {

  evaluationSearchForm: UntypedFormGroup;
  displayedColumns: string[] = ['evaluationName', 'studentName', 'studentRegistrationNumber', 'campusName', 'courseName', 'deadLine', 'totalFollowUpAnswered', 'status', 'actions'];
  evaluations$: Observable<CnetPagedList<CnetEvaluationResult>>;
  offset = 0;
  @ViewChild(InternshipFilterComponent, { static: true }) internshipFilter: InternshipFilterComponent;
  @ViewChild(InstitutionFilterComponent, { static: true }) institutionhipFilter: InstitutionFilterComponent;
  @ViewChild(EvaluationFilterComponent, { static: true }) evaluationFilter: EvaluationFilterComponent;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private evaluationService: EvaluationService
  ) { }

  ngOnInit(): void {
    this.createFiltersForm();
    this.upadateEvaluations();
  }

  createFiltersForm() {
    this.evaluationSearchForm = this._formBuilder.group({
      evaluationName: ['', []],
      studentName: ['', []],
      evaluationStatus: ['', []],
      intershipFilter: this.internshipFilter?.createGroup(),
      institutionFilter: this.institutionhipFilter?.createGroup(),
      evaluationFilter: this.evaluationFilter?.createGroup(),
    });

    this.disableInternshipFilterValues();
  }

  private disableInternshipFilterValues() {
    this.internshipFilter?.disableControls([
      enumInternshipFilter.InternshipStatus,
      enumInternshipFilter.InternshipCondition,
      enumInternshipFilter.InternshipCreateDateFrom,
      enumInternshipFilter.InternshipCreateDateTo,
      enumInternshipFilter.InternshipWithAmmendment,
      enumInternshipFilter.InternshipWithIrregularAcademicStatis,
      enumInternshipFilter.InternshipWithSuspension,
      enumInternshipFilter.InternshipWithIntegrationAgency,
      enumInternshipFilter.InternshipWithEquivalence,
      enumInternshipFilter.InternshipWithFormsPendinValidation,
      enumInternshipFilter.InternshipWithPedingDocuments
    ]);
  }

  search() {
    this.offset = 0;
    this.upadateEvaluations();
  }


  upadateEvaluations() {

    let { intershipFilter, institutionFilter, evaluationFilter, ...filters } = this.evaluationSearchForm.value;

    filters = { ...intershipFilter, ...filters, ...institutionFilter, ...evaluationFilter };

    this.evaluations$ = this.evaluationService.getEvaluations(this.offset, filters);

    const pageSize = 10;
    this.evaluations$.pipe(tap(data => {

      this.offset = data.totalItemCount < pageSize ? 0 : this.offset;

    }));

  }

  onPageChange(e) {
    this.offset = e;
    this.upadateEvaluations()

  }
}
