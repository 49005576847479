import { inject, Injectable } from "@angular/core";
import { StartupService } from "../services/startup.service";

export enum Role {
  Faculty = 'Orientador',
  CnetAdmin = 'Administrador Contratanet',
  CompanyAdmin = 'Administrador da Empresa',
  Student = 'Estudante',
  PortalAdmin = 'Administrador de Portal',
  InstitutionAdmin = 'Administrador de Instituição',
  CampusAdmin = 'Administrador de Campus',
}
export enum RoleAsCode {
  Faculty = '21D78D4B-D6D6-4044-AEEE-3EFB60520430',
  CompanyAdmin = 'A0935137-9E0C-429C-BE83-D43F1A92573E',
  Student = '6411BF93-B7F2-4C09-90AE-D8EA89E09C40',
  InstitutionAdmin = 'B99CCE3E-D25C-4683-98BB-F255405109A5',
  CampusAdmin = '2B10FB81-6E4D-492F-B145-1F8108D24059',
}
export enum RoleCodeAsRole {
  '21D78D4B-D6D6-4044-AEEE-3EFB60520430' = 'Faculty',
  'A0935137-9E0C-429C-BE83-D43F1A92573E' = 'CompanyAdmin',
  '6411BF93-B7F2-4C09-90AE-D8EA89E09C40' = 'Student',
  'B99CCE3E-D25C-4683-98BB-F255405109A5' = 'InstitutionAdmin',
  '2B10FB81-6E4D-492F-B145-1F8108D24059' = 'CampusAdmin',
}



export type RolePermissionsType = 'advisor' | 'company' | 'student' | 'institution-admin' | 'campus-admin'

export enum RolePermissions {
  'advisor' = 'advisor',
  'company' = 'company',
  'student' = 'student',
  'institution-admin' = 'institution-admin',
  'campus-admin' = 'campus-admin',

}
export type RoleInstitutionPermissionsCodeType = '21D78D4B-D6D6-4044-AEEE-3EFB60520430' | 'B99CCE3E-D25C-4683-98BB-F255405109A5' | '2B10FB81-6E4D-492F-B145-1F8108D24059'

export enum RolePermissionsCode {
  'company' = 'A0935137-9E0C-429C-BE83-D43F1A92573E',
  'student' = '6411BF93-B7F2-4C09-90AE-D8EA89E09C40',
  'institution-admin' = 'B99CCE3E-D25C-4683-98BB-F255405109A5',
  'campus-admin' = '2B10FB81-6E4D-492F-B145-1F8108D24059',
  'advisor' = '21D78D4B-D6D6-4044-AEEE-3EFB60520430',

}
export enum RolePermissionsTranslate {
  'advisor' = 'Orientador',
  'company' = 'Administrador da Empresa',
  'student' = 'Estudante',
  'institution-admin' = 'Administrador de Instituição',
  'campus-admin' = 'Administrador de Campus',
  'admin-cnet' = 'Administrador Contratanet'
}

export enum RolePermissionsCodeTranslate {
  '21D78D4B-D6D6-4044-AEEE-3EFB60520430' = RolePermissionsTranslate.advisor,
  'A0935137-9E0C-429C-BE83-D43F1A92573E' = RolePermissionsTranslate.company,
  '6411BF93-B7F2-4C09-90AE-D8EA89E09C40' = RolePermissionsTranslate.student,
  'B99CCE3E-D25C-4683-98BB-F255405109A5' = RolePermissionsTranslate["institution-admin"],
  '2B10FB81-6E4D-492F-B145-1F8108D24059' = RolePermissionsTranslate["campus-admin"],

}


export enum RolePermissionsDescriptions {
  'advisor' = 'Professores responsáveis por um ou mais estágios.',
  'company' = 'Responsável pela empresa no sistema.',
  'student' = 'Estagiários e candidatos.',
  'institution-admin' = 'Responsável pela gestão do portal.',
  'campus-admin' = 'Responsável pela gestão de campi do portal'

}

export function enumPermissionsRolesAsArray() {
  const permissionsRolesTypes = RolePermissionsCode;
  return Object.keys(permissionsRolesTypes).map(type => { return { id: RolePermissionsCode[type], title: RolePermissionsTranslate[type], description: RolePermissionsDescriptions[type] } });
}
export function getRoleByCode(code: string) {
  const roles = enumPermissionsRolesAsArray();
  return roles.find(role => role.id?.trim().toLowerCase() === code?.trim().toLowerCase());
}

export function getTranslatedRoleByCode(code: string) {
  const role = Object.keys(RolePermissionsCode).find(r => RolePermissionsCode[r]?.trim().toLowerCase() === code?.trim().toLowerCase());
  return RolePermissionsTranslate[role];
}

export function isInstitutionRole(code: string): boolean {

  return code?.trim().toUpperCase() === RolePermissionsCode.advisor || code?.trim().toUpperCase() === RolePermissionsCode["institution-admin"] || code?.trim().toUpperCase() === RolePermissionsCode["campus-admin"]

}

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  startupService = inject(StartupService)

  institutionPermissionsRoles() {
    const permissionsRolesTypes = RolePermissionsCode;
    return Object.keys(permissionsRolesTypes).filter(k => (k === RolePermissions.advisor && this.startupService.hasAdvisorRole()) || k === RolePermissions["institution-admin"]
     || (k === RolePermissions["campus-admin"] && this.startupService.hasCampusAdminRole()))
      .map(type => { return { id: RolePermissionsCode[type], title: RolePermissionsTranslate[type], description: RolePermissionsDescriptions[type] } });

  }

  getRoleNameByCode(code:string){
    return Role[RoleCodeAsRole[code.toUpperCase()]]
  }
}

