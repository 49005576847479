import { Injectable, inject } from '@angular/core';
import { CnetDialogService, ConfirmDialogComponent } from '@cnet/ngx-ui';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { take } from 'rxjs';
import { AuthService } from './auth.service';
import { StartupService } from './startup.service';


@Injectable({
  providedIn: 'root'
})
export class IdleHandlerService {

  private idle = inject(Idle);
  private keepalive = inject(Keepalive);
  private authService = inject(AuthService);
  private startupService = inject(StartupService);
  private cnetDialogService = inject(CnetDialogService)

  ref = null;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  confirmRef = null;

  start() {

    if (this.startupService.allowSessionIdleTimeout()) {

      const minutesToLimitSessionIdleTimeout = this.startupService.minutesToLimitSessionIdleTimeout() * 60

      this.idle.setIdle(minutesToLimitSessionIdleTimeout);
      this.idle.setTimeout(10);
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      this.idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.'
        if (this.confirmRef) {
          setTimeout(() => {
            this.cnetDialogService.close()
          }, 2000);
        }
        this.reset();
      });
      this.idle.onTimeout.pipe(take(1)).subscribe(() => {
        this.idleState = 'Timed out!';
        this.timedOut = true;

        this.authService.logoutAndRedirectToLogin();
        const message = 'Sua sessão expirou!';
        this.cnetDialogService.open(ConfirmDialogComponent, { message, rejectVisible: false, acceptLabel: 'ok' });
        this.idle.onTimeout.complete()
      });
      this.idle.onIdleStart.subscribe(() => {
        const header = 'Você ainda está aí?'
        const message = 'Sua sessão parece estar inativa e logo irá expirar. Caso você esteja aí clique em fechar ou interaja com o sistema.';
        this.confirmRef = this.cnetDialogService.open(ConfirmDialogComponent, { header, message, rejectVisible: false, acceptLabel: 'fechar' });
        this.idleState = 'You\'ve gone idle!'
      });
      this.idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleState = 'You will time out in ' + countdown + ' seconds!'
      });

      this.keepalive.interval(15);
      this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

      this.authService.getUserLoggedIn().subscribe(userLoggedIn => {
        if (userLoggedIn) {
          this.idle.watch()
          this.timedOut = false;
        } else {
          this.idle.stop();
        }
      })


      this.resetIfLoggedIn();
    }

  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  resetIfLoggedIn() {
    if (this.authService.isLoggedIn()) {
      this.reset();
    }
  }

}
