import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderToolbarComponent } from './header-toolbar.component';
import { SharedModule } from '../../shared.module';
import { CnetNotificationsBellModule } from '@cnet/ngx-ui';
import { ProfileMenuCardModule } from '../profile-menu-card/profile-menu-card.module';
import { LanguageSwitcherModule } from '../language-switcher/language-switcher.module';
import { SandwitchMenuModule } from './sandwitch-menu/sandwitch-menu.module';



@NgModule({
  declarations: [
    HeaderToolbarComponent
  ],
  exports: [
    HeaderToolbarComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CnetNotificationsBellModule,
    ProfileMenuCardModule,
    LanguageSwitcherModule,
    SandwitchMenuModule
  ]
})
export class HeaderToolbarModule { }
