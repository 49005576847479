import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusTypePipe } from './status-type-translate.pipe';



@NgModule({
  declarations: [StatusTypePipe],
  imports: [
    CommonModule
  ],
  exports: [StatusTypePipe],
})
export class StatusTypePipeModule { }
