import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { BasicModel } from 'src/app/core/models/basic-model';
import { InternshipService } from 'src/app/core/services/internship.service';
import { AbstractFilter } from '../abstract-filter';
import { InternshipFilterType } from './internship-filter-type';

@Component({
  selector: 'app-internship-filter',
  templateUrl: './internship-filter.component.html',
  styleUrls: ['./internship-filter.component.scss']
})
export class InternshipFilterComponent extends AbstractFilter<InternshipFilterType> {

  InternshipStatus$: Observable<Array<BasicModel>> = this.internshipService.getAllInternshipStatus();
  conditions$: Observable<Array<BasicModel>> = this.internshipService.getAllInternshipSubStatus();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private internshipService: InternshipService
  ) { super() }

  override  createGroup(): UntypedFormGroup {
    this.filterFormGroup = this.formBuilder.group({
      InternshipStatus: ['', []],
      InternshipCondition: ['', []],
      InternshipIsMandatory: ['', []],

      InternshipDateFrom: ['', []],
      InternshipDateTo: ['', []],

      InternshipCreateDateFrom: ['', []],
      InternshipCreateDateTo: ['', []],

      InternshipWithAmmendment: [false, []],
      InternshipWithIrregularAcademicStatis: [false, []],

      InternshipWithSuspension: [false, []],
      InternshipWithIntegrationAgency: [false, []],
      InternshipWithEquivalence: [false, []],

      InternshipWithFormsPendinValidation: [false, []],
      InternshipWithPedingDocuments: [false, []],
    });

    return this.filterFormGroup;
  }

  getAmountFiltersWithValue() {
    const controlsWithValue = this.getFilledFilters();

    if (controlsWithValue.includes('InternshipDateFrom') && controlsWithValue.includes('InternshipDateTo')) {
      controlsWithValue.splice(controlsWithValue.indexOf('InternshipDateTo'), 1);
    }
    if (controlsWithValue.includes('InternshipCreateDateFrom') && controlsWithValue.includes('InternshipCreateDateTo')) {
      controlsWithValue.splice(controlsWithValue.indexOf('InternshipCreateDateTo'), 1);
    }
    
    return controlsWithValue.length;
  }

}
