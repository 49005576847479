import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EvaluationService } from '../../../evaluation.service';

@Component({
  selector: 'app-evaluation-config-list',
  templateUrl: './evaluation-config-list.component.html',
  styleUrls: ['./evaluation-config-list.component.scss']
})
export class EvaluationConfigListComponent implements OnInit {

  notMandatoryInternships$;
  mandatoryInternships$;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private evaluationService: EvaluationService

  ) { }

  ngOnInit(): void {
    this.mandatoryInternships$ = this.evaluationService.watchMandatoryEvaluationConfigs();
    this.notMandatoryInternships$ = this.evaluationService.watchNotMandatoryEvaluationConfigs();
  }

  navigateToConfig(code) {
    var path = "'/evaluation/'+code}'/details'";
    this.router.navigate(['../evaluation-config/details', code], { relativeTo: this.activatedRoute });
  }

}
