import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { PortalRenderModule } from '../legacy/components/portal-render/portal-render.module';
import { map, tap } from 'rxjs';
import { SanitizerUrlModule } from 'src/app/shared/pipes/sanitizer-url/sanitizer-url/sanitizer-url.module';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-new-tab-helper',
  standalone: true,
  imports: [CommonModule, PortalRenderModule, SanitizerUrlModule, SharedModule],
  templateUrl: './new-tab-helper.component.html',
  styleUrls: ['./new-tab-helper.component.scss']
})
export class NewTabHelperComponent implements OnInit {

  url$ = this.activatedRoute.data.pipe(map(data => data.url) );

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
  }

}
