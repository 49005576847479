import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IframeRenderedService {

  constructor() { }

  isAppRenderedinsideIframe() {
    return window.self !== window.top;
  }

}
