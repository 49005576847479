import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private callsNumber: number;
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.callsNumber = 0;
    this.isLoading = new BehaviorSubject(false);
  }

  getLoading(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  public show() {
    this.callsNumber++;
    if (this.callsNumber === 1) {
      this.isLoading.next(true);
    }
  }

  public hide() {
    if (this.callsNumber > 0) {
      this.callsNumber--;
    }
    if (this.callsNumber === 0) {
      this.isLoading.next(false);
    }
  }

  public forceHide() {
    // Quando uma página legada é chamada o loaderservice é chamado na seguinte sequência:
    // * app.component -> show
    // * portal-render.component -> show
    // * app.component -> hide
    // * portal-render.component quando o iframe termina de carregar (demora) -> hide
    // Caso o usuário mude de tela antes do iframe ter carregado, o loading não some porque o número de shows fica maior que o número de hides,
    // já que o iframe demora mais que o spa a carregar. Por isso, quando o iframe estiver completamente carregado, força o hide do loading.

    this.callsNumber = 0;
    this.isLoading.next(false);

  }
}
