import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { BlockNotLoggedAndTypeNotInstitution } from 'src/app/core/guards/block-not-logged-and-type-institution.guard';
import { LogoutAndNavigatePublicRouteGuard } from 'src/app/core/guards/logout-and-navigate-public-route.guard';
import { LoginGuard } from 'src/app/modules/authentication/login-guard/login.guard';
import { LegacyRenderComponent } from '../../pages/legacy-render/legacy-render.component';
import { SIGNUP_LEGACY_ROUTES } from './signup-routes';

export const PUBLIC_LEGACY_ROUTES: Routes = [
  {
    path: 'signup', children: SIGNUP_LEGACY_ROUTES,
    data: {
      hideMenu: true
    },
    canActivate: [LoginGuard], canActivateChild: [LoginGuard]
  },
  {
    path: 'invite',
    component: LegacyRenderComponent,
    canActivate: [LogoutAndNavigatePublicRouteGuard],
    data: {
      name: 'Convite',
      legacyRoute: 'confere-convite',
      hideMenu: true
    },
  },
  {
    path: 'open-jobs',
    component: LegacyRenderComponent,
    data: {
      name: 'Vagas',
      legacyRoute: 'vagas',
      hideMenu: true
    }
  },
  {
    path: 'jobs/view', component: LegacyRenderComponent,
    data: {
      name: 'Vagas',
      legacyRoute: 'vaga/visualizar.aspx',
      hideMenu: true
    },
  },
  {
    path: 'job/:jobType/:local/:job/:id',
    component: LegacyRenderComponent,
    data: {
      name: 'Vagas',
      legacyRoute: '',
      hideMenu: true
    },
    canActivate: [BlockNotLoggedAndTypeNotInstitution]
  },
  {
    path: 'change-password',
    component: LegacyRenderComponent,
    data: {
      name: 'Alterar senha',
      legacyRoute: 'comuns/trocasenhausuario.aspx',
      hideMenu: true
    },
  },
  {
    path: 'internships/validate-digital-signature',
    component: LegacyRenderComponent,
    data: {
      name: 'Autenticação de documentos',
      legacyRoute: 'm/estagio/assinatura/autenticar',
      hideMenu: true
    },
  },
  {
    path: 'agreements/validate-digital-signature',
    component: LegacyRenderComponent,
    data: {
      name: 'Autenticação de documentos',
      legacyRoute: 'm/convenio/assinatura/autenticar',
      hideMenu: true
    },
  },
  {
    path: 'invite-user',
    component: LegacyRenderComponent,
    canActivate: [LogoutAndNavigatePublicRouteGuard],
    data: {
      name: 'Cadastro de Usuário',
      legacyRoute: 'cadastro/ConviteUsuario.aspx',
      hideMenu: true
    },
  },
  {
    path: 'enable-account',
    component: LegacyRenderComponent,
    canActivate: [LogoutAndNavigatePublicRouteGuard],
    data: {
      name: 'Habilitar Conta',
      legacyRoute: 'estudante/habilitarconta.aspx',
      hideMenu: true
    },
  },
  {
    path: 'unsubscribe',
    component: LegacyRenderComponent,
    data: {
      name: 'Cancelar recebimento de email',
      legacyRoute: 'comuns/cancelaremail.aspx'
    }
  },
  {
    path: 'followup',
    component: LegacyRenderComponent,
    data: {
      name: 'Acompanhamento',
      legacyRoute: 'estagios/gestao',
      hideMenu: true
    }
  },
  {
    path: 'api-documentation',
    component: LegacyRenderComponent,
    data: {
      name: 'Documentação API',
      legacyRoute: 'documentacao/api/user',
      hideMenu: true
    }
  },
  { path: 'cv',  component: LegacyRenderComponent,
    canActivate: [AuthGuard],
    data: {
        name: 'Currículo',
        legacyRoute: 'cv',
        hideMenu: true
      }
  },
  { path: 'privatecv',  component: LegacyRenderComponent,
    canActivate: [AuthGuard],
      data: {
        name: 'Currículo',
        legacyRoute: 'privatecv',
        hideMenu: true
      }
  },
];
