import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AppIframeTrackerModule } from '../../directives/app-iframe-tracker/app-iframe-tracker.module';
import { SanitizerUrlModule } from '../../pipes/sanitizer-url/sanitizer-url/sanitizer-url.module';

@Component({
  selector: 'app-iframe-handler',
  standalone: true,
  imports: [CommonModule, SanitizerUrlModule, AppIframeTrackerModule],
  templateUrl: './iframe-handler.component.html',
  styleUrls: ['./iframe-handler.component.scss']
})
export class IframeHandlerComponent {
  @Input() src
}
