import { Component } from '@angular/core';

@Component({
  selector: 'app-results-not-found',
  template: `
  <div class="ctn-not-found-wrapper">
    <div class="ctn-not-found-icon">
      <cnet-icon label="search" name="search" size="1"></cnet-icon>
    </div>
    <div class="ctn-not-found-text">
      <P>
        {{'Nenhum resultado encontrado' | translate}}
      </P>
    </div>
</div>
`,
  styleUrls: ['./results-not-found.component.scss']
})
export class ResultsNotFoundComponent {
}
