import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { Role } from './core/enums/role';
import { AuthGuard } from './core/guards/auth.guard';
import { BlockNotLoggedAndTypeNotInstitution } from './core/guards/block-not-logged-and-type-institution.guard';
import { MaintenanceGuard } from './core/guards/maintenance.guard';
import { StartupGuard } from './core/guards/startup.guard';
import { StartupService } from './core/services/startup.service';
import { LoginGuard } from './modules/authentication/login-guard/login.guard';
import { SigninSymplicityComponent } from './modules/authentication/pages/login/redirect/csm/signin-symplicity/signin-symplicity.component';
import { SigninSymplicityGuard } from './modules/authentication/pages/login/redirect/csm/signin-symplicity/signin-symplicity.guard';
import { CompanyUpdateCodeTokenRedirectGuard } from './modules/company/guards/company-update-code-token-redirect.guard';
import { DigitalSignatureGuard } from './modules/digital-signature/guards/digital-signature.guard';
import { LegacyRenderComponent } from './modules/legacy/pages/legacy-render/legacy-render.component';
import { ApiUrlBlobResolver } from './modules/new-tab-helper/api-url-blob.resolver';
import { NewTabHelperComponent } from './modules/new-tab-helper/new-tab-helper.component';
import { DownloadReportGuard } from './shared/guards/download-report.guard';
import { LoginCredentialGuard } from './shared/guards/login-credential.guard';
import { SiaGuard } from './shared/guards/sia.guard';
import { IframeErrorComponent } from './shared/pages/iframe-error/iframe-error.component';
import { InternalServerErrorComponent } from './shared/pages/internal-server-error/internal-server-error.component';
import { InvalidCompanyComponent } from './shared/pages/invalid-company/invalid-company.component';
import { InvalidTokenComponent } from './shared/pages/invalid-token/invalid-token.component';
import { MaintenanceComponent } from './shared/pages/maintenance/maintenance.component';
import { NotFoundComponent } from './shared/pages/not-found/not-found.component';
import { UnauthorizedComponent } from './shared/pages/unauthorized/unauthorized.component';
import { UrlAuthRedirectGuard } from './shared/pages/url-auth-redirect/url-auth-redirect.guard';
import { CampusAdminWithoutCampusComponent } from './shared/pages/login-error/institution-error/campus-admin-without-campus/campus-admin-without-campus.component';

export const ANGULAR_ROUTES = [
  {
    path: 'evaluation', loadChildren: () => import('./modules/evaluation/evaluation.module').then(m => m.EvaluationModule),
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Avaliações',
      roles: [Role.InstitutionAdmin, Role.Faculty, Role.CampusAdmin],
      permissions: {
        only: 'EvaluationModuleAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'clipboard-list',
    }
  },
  {
    path: 'academic-period', loadChildren: () => import('./modules/academic-calendar/academic-period.module').then(m => m.AcademicCalendarModule),
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Calendário Acadêmico',
      roles: [Role.InstitutionAdmin],
      permissions: {
        only: 'AcademicPeriodModuleAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'home',
      profileMenuItens: true,
      profileMenuItenOrder: 5
    }
  },
  {
    path: 'documents',
    loadChildren: () => import('./modules/document-configurations/document-configurations.module').then(m => m.DocumentConfigurationsModule),
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Documentos',
      roles: [Role.CnetAdmin],
      permissions: {
        only: 'SettingsDocumentAccess',
        redirectTo: '/unauthorized'
      },
      notInHeaderMenu: true
    }
  },
  {
    path: 'follow-up', loadChildren: () => import('./modules/follow-up/follow-up.module').then(m => m.FollowUpModule),
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Acompanhamento',
      roles: [Role.InstitutionAdmin, Role.CampusAdmin],
      permissions: {
        only: 'InternshipFollowUpVisualize',
        redirectTo: '/unauthorized'
      },
      icon: 'clipboard-list',
    }
  },
  {
    path: 'companies', loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule),
    data: {
      name: 'Cadastro de empresas',
      roles: [Role.InstitutionAdmin, Role.CampusAdmin],
      permissions: {
        only: ['InternshipModuleAccess', 'OpportunityModuleAccess'],
        redirectTo: '/unauthorized'
      },
      icon: 'home',
      notInHeaderMenu: true
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/portal-configurations/portal-configurations.module').then(m => m.PortalConfigurationsModule),
    canActivate: [NgxPermissionsGuard, AuthGuard],
    data: {
      name: 'Configurações',
      permissions: {
        only: [
          'SettingsAgreementModuleAccess',
          'SettingsInternshipModuleAccess',
          'SettingsSystemAccess',
          'SettingsEvaluationModuleAccess',
          'SettingsOpportunityModuleAccess',
          'SettingsDocumentAccess',
          'SettingsInsuranceAccess',
          'SettingsUserPermission',
          'SettingsGroupPermission',
          'SettingsPortalLayoutAccess',
          'InstitutionTeamVisualize'
        ],
        redirectTo: '/unauthorized'
      },
      roles: [Role.CnetAdmin, Role.InstitutionAdmin],
      headerFixedIcon: true,
      icon: 'cog',
    }
  },
  {
    path: 'update-company',
    canActivate: [AuthGuard, NgxPermissionsGuard, CompanyUpdateCodeTokenRedirectGuard],
    loadChildren: () => import('./modules/company/pages/company-logged-form/company-logged-form.module').then(m => m.CompanyLoggedFormModule),
    data: {
      name: 'Editar Empresa',
      returnUrl: null,
      roles: [Role.CompanyAdmin],
      permissions: {
        redirectTo: '/unauthorized'
      },
      profileMenuItens: true,
      profileMenuItenOrder: 3
    },
  },
  {
    path: 'internships',
    loadChildren: () => import('./modules/internships/internships.module').then(m => m.InternshipsModule),
    data: {
      profileMenuItens: false,
      notInHeaderMenu: true,
    },
  },
  {
    path: 'campus',
    canActivate: [NgxPermissionsGuard],
    loadChildren: () => import('./modules/campus/campus.module').then(m => m.CampusModule),
    data: {
      name: 'Campus',
      returnUrl: null,
      permissions: {
        only: 'InternshipModuleAccess',
        redirectTo: '/unauthorized'
      },
      profileMenuItens: false,
      notInHeaderMenu: true,
    },
  },
  {
    path: 'document',
    component: NewTabHelperComponent,
    data: {
      notInHeaderMenu: true,
      hideHeader: true,
      hideFooter: true,
    },
    resolve: {
      url: ApiUrlBlobResolver
    }
  },
  {
    path: 'courses',
    loadComponent: () => import('./modules/teammate/pages/teammate-courses/teammate-courses.component').then(c => c.TeammateCoursesComponent),
    canActivate: [NgxPermissionsGuard, AuthGuard],
    data: {
      name: 'Cursos',
      roles: [Role.Faculty],
      permissions: {
        only: 'CampusCourseVisualize',
        redirectTo: '/unauthorized'
      },
      icon: 'home',
      profileMenuItens: true,
      profileMenuItenOrder: 5
    },
  },
];

const MAINTENANCE_ROUTES = [
  {
    path: 'maintenance', component: MaintenanceComponent,
    canActivate: [MaintenanceGuard],
    data: {
      hideHeader: true,
      hideFooter: true
    }
  }
];

const ERRROS_ROUTES = [
  { path: 'not-found', component: NotFoundComponent },
  {
    path: 'iframe-rendered', component: IframeErrorComponent,
    data: {
      hideHeader: true,
      hideFooter: true
    }
  },
  { path: 'internal-server-error', component: InternalServerErrorComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'invalid-company', component: InvalidCompanyComponent },
  {
    path: 'csm-errors', component: SigninSymplicityComponent,
    canActivate: [SigninSymplicityGuard],
    resolve: {
      errorGuid: SigninSymplicityGuard
    },
    data: {
      hideFooter: true
    }
  },
  {
    path: 'download-report', component: LegacyRenderComponent,
    canActivate: [DownloadReportGuard],
    data: {
      roles: [Role.InstitutionAdmin],
      permissions: {
        only: 'ManageReportAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'home',
      notInHeaderMenu: true
    }
  },
  { path: 'invalid-token', component: InvalidTokenComponent },
  {
    path: 'campus-admin-without-campus', loadComponent:
      () => import('./shared/pages/login-error/institution-error/campus-admin-without-campus/campus-admin-without-campus.component')
        .then((c) => c.CampusAdminWithoutCampusComponent)
  }
];

const SIA_ROUTES = [
  { path: 'comuns/redirecionaautenticado.aspx', component: InvalidTokenComponent, canActivate: [SiaGuard] },
  { path: 'comuns/RedirecionaAutenticado.aspx', component: InvalidTokenComponent, canActivate: [SiaGuard] },
];

const JOBS_ROUTES = [
  {
    path: 'vaga-emprego/:city/:description/:id',
    loadChildren: () => import('./modules/jobs/jobs.module').then(m => m.JobsModule),
    canActivate: [BlockNotLoggedAndTypeNotInstitution]
  },
  {
    path: 'vaga-jovem-aprendiz/:city/:description/:id',
    loadChildren: () => import('./modules/jobs/jobs.module').then(m => m.JobsModule),
    canActivate: [BlockNotLoggedAndTypeNotInstitution]

  },
  {
    path: 'vaga-estagio/:city/:description/:id',
    loadChildren: () => import('./modules/jobs/jobs.module').then(m => m.JobsModule),
    canActivate: [BlockNotLoggedAndTypeNotInstitution]

  },
];


const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
    canActivateChild: [LoginGuard]
  },
  {
    path: 'l', loadChildren: () => import('./modules/legacy/legacy.module').then(m => m.LegacyModule),
    canActivate: [LoginCredentialGuard]
  },
  {
    path: '**', loadChildren: () => import('./shared/pages/home/home.module').then(m => m.HomeModule),
    canActivate: [UrlAuthRedirectGuard],
    data: {
      hideHeader: false,
      hideFooter: false
    }

  },

];

const allRoutes = [
  ...MAINTENANCE_ROUTES,
  {
    path: '',
    canActivate: [StartupGuard],
    children: [
      ...ANGULAR_ROUTES, ...SIA_ROUTES, ...JOBS_ROUTES, ...ERRROS_ROUTES, ...routes
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(allRoutes, { onSameUrlNavigation: 'reload', paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})

export class AppRoutingModule {
  legacyUrl: string;
  constructor(private _startupService: StartupService) {
    this.legacyUrl = _startupService.getLegacyUrl();
  }

}
