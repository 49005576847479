import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from 'src/app/core/services/language.service';
import { LoaderService } from 'src/app/core/services/shared/loader.service';
import { UrlAuthRedirectService } from 'src/app/shared/pages/url-auth-redirect/url-auth-redirect.service';
import { environment } from 'src/environments/environment';
import { PortalRenderService } from './portal-render.service';
@Component({
  selector: 'app-portal-render',
  templateUrl: './portal-render.component.html',
  styleUrls: ['./portal-render.component.scss']
})

export class PortalRenderComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('iframe') iframe: any;

  heightHeader = environment.env !== 'prd' ? 90 : 60
  heightFooter = 60

  public _path: string;
  @Input() credential: string;

  @Input()
  get path(): string { return this._path; }

  set path(value: string) {
    if (value) {
      const index = value.indexOf('#') == -1 ? value.length - 1 : value.indexOf('#');
      const char = value.substring(0, index).includes('?') ? '&' : '?';
      const token = this.activeRoute.snapshot.paramMap.get('token');
      const protocol = `${value.includes('http') ? '' : `${environment.httpSchema}://`}`;

      if (value.includes('#')) {
        if (this.credential) {
          this._path = protocol + value.replace('#', `${char}credential=${this.credential}#`);
        }
        else {
          this._path = `${protocol}${value}`;
        }

      } else {
        if (token) {
          this._path = `${protocol}${value}${char}${this.credential ? `credential=${this.credential}` : ''}&token=${token}`;
        } else {
          this._path = `${protocol}${value}${char}${this.credential ? `credential=${this.credential}` : ''}`;
        }
      }

      this.loaderService.show();
      setTimeout(() => {
        this.setIframeSrc(this._path);
      }, 100);

    }
  }

  languageObservable$;

  constructor(
    private loaderService: LoaderService,
    private activeRoute: ActivatedRoute,
    private languageService: LanguageService,
    private _urlAuthRedirectService: UrlAuthRedirectService,
    private portalRenderService: PortalRenderService
  ) { }

  ngOnInit(): void {
    this.heightHeader = this.activeRoute.snapshot.data.hideHeader ? 0 : environment.env !== 'prd' ? 90 : 60;
    this.heightFooter = this.activeRoute.snapshot.data.hideFooter ? 0 : 60;
    this.languageObservable$ = this.languageService.observeReload().subscribe(() => {
      this.reloadIframe();
    });


  }

  ngAfterViewInit() {
    this.iframe.nativeElement.addEventListener('load', this.onIframeLoad.bind(this));
  }

  reloadIframe() {

    let oldurl = '';
    this.loaderService.show();
    this.setIframeSrc('');
    if (this._urlAuthRedirectService.currentIframeUrl) {
      const protocol = `${this._urlAuthRedirectService.currentIframeUrl.includes('http') ? '' : `${environment.httpSchema}://`}`;
      oldurl = protocol + this._urlAuthRedirectService.currentIframeUrl;
    } else {
      if (this._path) {
        const protocol = `${this._path.includes('http') ? '' : `${environment.httpSchema}://`}`;
        oldurl = `` + protocol + this._path;
      }
    }
    this.setIframeSrc(oldurl);
  }

  onIframeLoad(e) {
    this.portalRenderService.end();
    this.loaderService.forceHide();
  }

  setIframeSrc(src) {
    this.portalRenderService.start();
    this.iframe.nativeElement.contentWindow.location.replace(src);
  }

  ngOnDestroy(): void {
    this.languageObservable$.unsubscribe();
  }
}
