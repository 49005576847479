import { Routes } from '@angular/router';
import { LegacyRenderComponent } from '../../pages/legacy-render/legacy-render.component';

export const SIGNUP_LEGACY_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/'
  },
  {
    path: 'company',
    component: LegacyRenderComponent,
    data: {
      name: 'Cadastro de Empresas',
      legacyRoute: 'cadastro-empresa',
    },
  },
  {
    path: 'candidate',
    component: LegacyRenderComponent,
    data: {
      name: 'Cadastro de Candidato',
      legacyRoute: 'cadastro-curriculo',
    },
  },
  {
    path: 'reset-password',
    component: LegacyRenderComponent,
    data: {
      name: 'Esqueci minha senha',
      legacyRoute: 'esqueci-minha-senha'
    }
  }
];
