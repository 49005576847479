import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlAuthRedirectService {

  previousSrcUrl = '';

  currentIframeUrl = '';
  constructor() { }

  cleanPreviousSrcUrl() {
    this.previousSrcUrl = '';
  }
  cleanCurrentIframeUrl() {
    this.previousSrcUrl = '';
  }
}
