import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from '../analytics/google-analytics.service';
import { PortalConfigurations } from '../models/portal-configurations.model';
import { PortalType } from '../models/portal.model';
import { SocialMedia } from '../models/socialMedia.model';
import { StartupConfig } from '../models/startup-config.model';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root'
})
export class StartupService {
  hasCorsErrorOnRequestDifferentThanStartup = false;
  private _config: BehaviorSubject<StartupConfig>;
  cookieService = inject(CookieService)
  googleAnalyticsService = inject(GoogleAnalyticsService)
  private _legacyUrl: string;

  constructor(private http: HttpClient, private uiService: UiService) {
    this._config = new BehaviorSubject<StartupConfig>(null);
  }

  init(): Observable<StartupConfig> {

    const cookieStartup = this.cookieService.get('startup-info');
    if (!cookieStartup) {

      return this.initRequest();
    }

    let config: StartupConfig = JSON.parse(cookieStartup);

    this.setConfig(config);
    this.uiService.setUiByConfig(config);
    this.setGoogleAnalytics();
    return of(config)
  }

  private setGoogleAnalytics() {
    this.googleAnalyticsService.addGAScripts();
  }

  initRequest() {

    return this.http.get<StartupConfig>(`${environment.apiUrl}/startup-service?url=${window.location.host}`)
      .pipe(tap(config => {
        if (config) {
          this.setConfig(config);
          this.setStartupCookie(config)
          this.uiService.setUiByConfig(config)
        }
      }))
  }

  private setStartupCookie(config: StartupConfig) {
    const myDate: Date = new Date();
    myDate.setMinutes(myDate.getMinutes() + 30);
    this.cookieService.set('startup-info', JSON.stringify(config), { expires: myDate, secure: true });
  }

  setConfig(config: StartupConfig) {
    localStorage.setItem('legacyUrl', config.portal.legacyUrl);
    this._config.next(config);

  }
  getStartupCookie() {
    return this.cookieService.get('startup-info');
  }

  deleteStartupCookie() {
    this.cookieService.delete('startup-info');
  }

  getPortalConfig(): BehaviorSubject<StartupConfig> {
    return this._config;
  }

  getPortalCode(): number {
    return this._config.getValue()?.portal.code;
  }
  getRegion(): string {
    return this._config.getValue()?.portal.region;
  }

  getSocialMedia(): SocialMedia {
    return this._config.getValue()?.portal.socialMedia;
  }

  getSymplicityUrl(): string {
    return this._config.getValue()?.portal.symplicityUrl;
  }

  hasCSMIntegration(): boolean {
    return !!this._config.getValue()?.portal.symplicityUrl;
  }

  getPortalType(): PortalType {
    return this._config.getValue()?.portal.type;
  }

  getPortalConfigurations(): PortalConfigurations {
    return this._config.getValue()?.configurations;
  }

  allowExternalCountry(): boolean {
    return this._config.getValue()?.configurations.allowExternalCountry;
  }
  showFieldStateRegistrationCompany(): boolean {
    return this._config.getValue()?.configurations.showFieldStateRegistrationCompany;
  }
  showFieldIndividualHirerPartCompany(): boolean {
    return this._config.getValue()?.configurations.showFieldIndividualHirerPartCompany;
  }
  allowSerproIntegration(): boolean {
    return this._config.getValue()?.configurations.allowSerproIntegration;
  }
  allowSessionIdleTimeout(): boolean {
    return this._config.getValue()?.configurations.allowSessionIdleTimeout;
  }
  minutesToLimitSessionIdleTimeout(): number {
    return this.allowSessionIdleTimeout() ? this._config.getValue()?.configurations.minutesToLimitSessionIdleTimeout : 30;
  }
  showFieldIndividualHirerRegistrationCompany(): boolean {
    return this._config.getValue()?.configurations.showFieldIndividualHirerRegistrationCompany;
  }
  showLanguageOption(): boolean {
    return this._config.getValue()?.configurations.languageOption;
  }
  hasCampusAdminRole(): boolean {
    return this._config.getValue()?.configurations.hasCampusAdminUser;
  }

  hasAdvisorStepOnExternalDocumentFlow() {
    return this._config.getValue()?.configurations.hasAdvisorStepOnExternalDocumentFlow;
  }

  /**
   * return the portal has some integration
   * @returns
   */
  isPortalWithIntegration() {
    return this.hasIesIntegration();
  }

  setLogo(logoUrl) {
    this._config.subscribe((config: StartupConfig) => {
      config.portal.darkLogoUrl = logoUrl;
    });
  }
  getLogo() {
    return this._config.getValue()?.portal.darkLogoUrl;
  }
  getFavIcon() {
    return this._config.getValue()?.portal.favIcoUrl;
  }

  getUrl(): string {
    return this._config.getValue()?.portal?.url;
  }

  getDefaultLanguage(): string {
    return this._config.getValue()?.portal?.defaultLanguage;
  }
  getDefaultLanguageByConfig(config: StartupConfig): string {
    return config?.portal?.defaultLanguage;
  }

  getLegacyUrl() {
    return localStorage.getItem('legacyUrl');
  }

  hasLandingPage() {
    return this._config.getValue()?.portal.hasLandingPage;
  }

  hasIesIntegration() {
    return this._config.getValue()?.portal.hasIesIntegration;
  }

  hasBlog() {
    const social = this.getSocialMedia();
    return social?.blog ? `https://${social?.blog}` : null;
  }
  hasAdvisorRole() {
    return this._config.getValue()?.portal.hasAdvisorRole;
  }

  isPortalContratanet() {
    return this._config.getValue()?.portal.code === 8;
  }
  isPortalEncontreSuaVaga() {
    return this._config.getValue()?.portal.code === 5;
  }
  isPortalSerEducacional() {
    return this._config.getValue()?.portal.code === 39;
  }
  isPortalMultiInstitution () {
    return this._config.getValue()?.portal.multiInstitution;
  }
  hasCSMIntegrationAndHasAdvisorRole(){
    return this.hasCSMIntegration() && this.hasAdvisorRole();
  }

  getCurrency(): string {
    return this._config.getValue()?.portal.currency;
  }
  digitalSignatureOnInternship() {
    return this._config.getValue()?.portal.digitalSignatureOnInternship;
  }
}
