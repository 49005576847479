import { Injectable, inject } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CreateDocumentOnNewStructureService } from 'src/app/core/services/create-document-on-new-structure.service';

@Injectable({
  providedIn: 'root'
})
export class ApiUrlBlobResolver  {
  createDocumentService = inject(CreateDocumentOnNewStructureService)

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    let params = route.queryParams;
    return this.createDocumentService.create(
      params.contractDocumentCode,
      params.type,
      params.language);
  }
}
