import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { Role } from 'src/app/core/enums/role';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { EvaluationActionsGuard } from './guards/evaluation-actions.guard';
import { EvaluationByEvaluationCodeResolver } from './guards/evaluation-by-evaluation-code.resolver';
import { EvaluationManualSignatureResolver } from './guards/evaluation-manual-signature.resolver';
import { ReturnUrlDetailsResolver } from './guards/return-url-details.resolver';
import { EvaluationConfigListComponent } from './pages/evaluation-home/evaluation-config-list/evaluation-config-list.component';
import { EvaluationHomeComponent } from './pages/evaluation-home/evaluation-home.component';
import { EvaluationMonitorComponent } from './pages/evaluation-home/evaluation-monitor/evaluation-monitor.component';

export const EVALUATION_ROUTES: Routes = [
  {
    path: '',
    component: EvaluationHomeComponent,
    children: [
      {
        path: '',
        redirectTo: 'monitor',
        pathMatch: 'full'
      },
      {
        path: 'monitor',
        component: EvaluationMonitorComponent,
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          baseRoute: true,
          roles: [Role.InstitutionAdmin, Role.Faculty, Role.CampusAdmin],
          permissions: {
            only: 'EvaluationModuleAccess',
            redirectTo: '/unauthorized'
          },
        },
      },
      {
        path: 'configure',
        component: EvaluationConfigListComponent,
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          baseRoute: true,
          name: 'Configurar Avaliação',
          roles: [Role.InstitutionAdmin],
          permissions: {
            only: 'EvaluationConfigModuleAccess',
            redirectTo: '/unauthorized'
          },
          icon: 'home'
        },
      }
    ],
  },
  {
    path: 'evaluation-config',
    loadChildren: () => import('./pages/evaluation-config/evaluation-config.module').then(m => m.EvaluationConfigModule),
  },
  {
    path: ':evaluationCode/survey/reply',
    loadChildren: () => import('./pages/evaluation-answer/evaluation-answer.module').then(m => m.EvaluationAnswerModule),
    canActivate: [AuthGuard, NgxPermissionsGuard, EvaluationActionsGuard],
    resolve: { evaluation: EvaluationByEvaluationCodeResolver },
    data: {
      baseRoute: true,
      roles: [Role.Faculty],
      permissions: {
        only: 'EvaluationSurveyReply',
        redirectTo: '/unauthorized'
      },
      action: 'isEligibleToReply',
      icon: 'home',
      notInHeaderMenu: true
    }
  },
  {
    path: ':evaluationCode/survey/details',
    loadChildren: () => import('./pages/evaluation-answer/evaluation-answer.module').then(m => m.EvaluationAnswerModule),
    canActivate: [AuthGuard, NgxPermissionsGuard, EvaluationActionsGuard],
    resolve: { evaluation: EvaluationByEvaluationCodeResolver },
    data: {
      baseRoute: true,
      roles: [Role.InstitutionAdmin, Role.Faculty, Role.CampusAdmin],
      permissions: {
        only: 'EvaluationModuleAccess',
        redirectTo: '/unauthorized'
      },
      action: "isEligibleToVisualizeForm",
      icon: 'home',
      notInHeaderMenu: true,
      readOnly : true
    }
  },
  {
    path: ':evaluationCode/manual-signature',
    loadChildren: () => import('./../manual-signature/manual-signature.module').then(m => m.ManualSignatureModule),
    canActivate: [AuthGuard, NgxPermissionsGuard, EvaluationActionsGuard],
    data: {
      baseRoute: true,
      subject: 'evaluation',
      label: 'avaliação',
      roles: [Role.InstitutionAdmin, Role.Faculty, Role.CampusAdmin],
      permissions: {
        only: 'EvaluationPhysicalSignature',
        redirectTo: '/unauthorized'
      },
      action: "isEligibleToSignDocuments",
      icon: 'home'
    },
    resolve: {
      document$: EvaluationManualSignatureResolver,
      returnUrl: ReturnUrlDetailsResolver
    },
  },
  {
    path: ':evaluationCode/digital-signature', loadChildren: () => import('../../modules/digital-signature/digital-signature.module').then(m => m.DigitalSignatureModule),
    canActivate: [AuthGuard, NgxPermissionsGuard, EvaluationActionsGuard],
    data: {
      name: 'Assinatura Digital',
      roles: [Role.Faculty],
      permissions: {
        only: 'EvaluationDigitalSignature',
        redirectTo: '/unauthorized'
      },
      action: "isEligibleToSignDocuments",
      icon: 'home',
      notInHeaderMenu: true
    },
    resolve: {
      document$: EvaluationManualSignatureResolver,
      returnUrl: ReturnUrlDetailsResolver
    },
  },
  {
    path: ':evaluationCode/details', loadChildren: () => import('../../modules/evaluation/pages/evaluation-details/evaluation-details.module').then(m => m.EvaluationDetailsModule),
    resolve: { evaluation: EvaluationByEvaluationCodeResolver },
    data: {
      name: 'Detalhes da avaliação',
      roles: [Role.InstitutionAdmin, Role.Faculty, Role.CampusAdmin],
      notInHeaderMenu: true
    },
  },
  {
    path: ':evaluationCode/reply/upload',
    loadChildren: () => import('./../evaluation/pages/evaluation-reply-upload/evaluation-reply-upload.module').then(m => m.EvaluationReplyUploadModule),
    // canActivate: [AuthGuard, NgxPermissionsGuard, EvaluationActionsGuard],
    data: {
      roles: [Role.InstitutionAdmin, Role.CampusAdmin],
      permissions: {
        only: 'ReceiveEvaluationDocument',
        redirectTo: '/unauthorized'
      },
      action:"isEligibleToReceiveDocuments",
      notInHeaderMenu: true
    },
    resolve: {
      returnUrl: ReturnUrlDetailsResolver
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(EVALUATION_ROUTES)],
  exports: [RouterModule]
})
export class EvaluationRoutingModule { }
