import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageContentComponent } from './page-content.component';
import { CnetCardModule } from '@cnet/ngx-ui';



@NgModule({
  declarations: [PageContentComponent],
  exports: [PageContentComponent],
  imports: [
    CommonModule,
    CnetCardModule
  ]
})
export class PageContentModule { }
