import { Directive, ElementRef, HostListener, Input, Renderer2, inject } from '@angular/core';
import { IdleHandlerService } from 'src/app/core/services/idle-handler.service';

@Directive({
  selector: '[appIframeTrackerDirective]'
})
export class IframeTrackerDirective {

  idleHandlerService = inject(IdleHandlerService);

  @Input() debug: boolean;
  private iframeMouseOver: boolean;
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.renderer.listen(window, 'blur', () => this.onWindowBlur());
  }


  @HostListener('mouseover')
  private onIframeMouseOver() {
    this.log('Iframe mouse over');
    this.iframeMouseOver = true;
    this.idleHandlerService.resetIfLoggedIn();
    this.resetFocusOnWindow();
  }

  @HostListener('mouseout')
  private onIframeMouseOut() {
    this.log('Iframe mouse out');
    this.iframeMouseOver = false;
    this.idleHandlerService.resetIfLoggedIn();
    this.resetFocusOnWindow();
  }

  private onWindowBlur() {
    if (this.iframeMouseOver) {
      this.log('WOW! Iframe click!!!');
      this.resetFocusOnWindow();
      this.idleHandlerService.resetIfLoggedIn();
    }
  }

  private resetFocusOnWindow() {
    setTimeout(() => {
      this.log('reset focus to window');
      // window.focus();
    }, 100);
  }

  private log(message: string) {
    if (this.debug) {
      console.log(message);
    }
  }

}
