import { Directive, ElementRef } from '@angular/core';
import { IframeRenderedService } from './iframe-rendered.service';

@Directive({
  selector: '[appIframeRendered]'
})
export class IframeRenderedDirective {

  constructor(el: ElementRef,
    private _iframeRenderedService: IframeRenderedService
  ) {
    if (this._iframeRenderedService.isAppRenderedinsideIframe()) {
      el.nativeElement.style.display = 'none';
    }
  }

}
