<ng-container *ngIf="config">

  <div [hidden]="isMobile">
    <cnet-toolbar color="secondary">
      <div class="header-container">

        <div class="img-logo d-flex align-items-center" [routerLink]="''">
          <img class="portal-logo" [src]="config.portal.darkLogoUrl" [alt]="config.portal.name">
        </div>

        <div #headerOptions class="d-flex" style="margin-left: auto; overflow: hidden; width: 100vw;">
          <ng-container *ngIf="user">
            <ng-container *ngFor="let route of routesHeader">
              <ng-container *ngxPermissionsExcept="route.data.permissions?.except">
                <div #menuOption [hidden]="!route.visible">
                  <a type="button" [id]="route.data.name" cnet-button [routerLink]="route.routerLink"
                    *ngxPermissionsOnly="route.data.permissions?.only" class="ml-0 menu-not-mobile btn-options"
                    (click)="test()" routerLinkActive="is-active">
                    <cnet-icon [size]="1" style="padding-right: 4px;" [name]="route.data.icon" label="route.data.icon">
                    </cnet-icon>
                    {{route.data.name | translate}}
                  </a>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

        </div>
        <ng-container *ngIf="user">
          <div *ngIf="routesHeaderBreaks.length" style="width: 54px"
            class="cnet-profile-menu-avatar cnet-profile-responsive-menu m-0" [cnet-menu-trigger]="menuOtherOptions">
            <cnet-icon name="bars">
            </cnet-icon>
          </div>
          <!-- notification bell -->
          <div class="cnet-profile-bell " [cnet-notifications-trigger]="cnetNotification">
            <ng-container>
              <cnet-notifications-bell id="cnet-notification-bell" #cnetNotification="cnetNotification"
                [size]="notifications?.length" [idleTime]="60000" (click.throttle)="getNotificationClick()" >
                <div class="box-notification">
                  <ng-container *ngIf="notifications?.length; else elseTemplate">
                    <div cnet-menu-item *ngFor="let item of notifications" (click)="navigateToRoute(item.url)"
                      class="notification-item">
                      <div [innerHtml]="item.description | translate:resolveParams(item)"></div>
                    </div>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <div cnet-menu-item>
                      {{"Você não tem notificações" | translate}}
                    </div>
                  </ng-template>
                </div>
              </cnet-notifications-bell>
            </ng-container>

          </div>
            <a style="width: 54px" class="cnet-profile-menu-avatar cnet-profile-responsive-menu m-0"
              [routerLink]="'settings'" routerLinkActive="is-active" style="color: white; text-decoration: none;"
              *ngxPermissionsOnly="[
                    'SettingsAgreementModuleAccess',
                    'SettingsInternshipModuleAccess',
                    'SettingsSystemAccess',
                    'SettingsEvaluationModuleAccess',
                    'SettingsOpportunityModuleAccess',
                    'SettingsDocumentAccess',
                    'SettingsInsuranceAccess',
                    'SettingsUserPermission',
                    'SettingsGroupPermission',
                    'SettingsPortalLayoutAccess',
                    'InstitutionTeamVisualize'
                    ]">
              <cnet-icon [size]="2" style="padding-right: 4px;" [name]="'cog'">
              </cnet-icon>
            </a>
          <!-- profile-menu   -->
          <div id="profile-menu-open" class="cnet-profile-menu-avatar m-0" [cnet-menu-trigger]="profileMenu"
            *ngIf="userDetails">
            <cnet-progress-spinner class="loading-img-usu" diameter="20" strokeWidth="2"
              *ngIf="!userDetails?.shortName">
            </cnet-progress-spinner>
            <cnet-avatar *ngIf="userDetails?.shortName" cnet-button class="user-avatar logout dropdown-toggle"
              size="normal" [src]="userDetails?.urlPhoto" [alt]="userDetails.shortName" [text]="userDetails.shortName">
            </cnet-avatar>
            <cnet-icon name="angle-down"></cnet-icon>
          </div>
        </ng-container>


        <!-- public items  -->
        <span *ngIf="!user" class="public-itens">
          <button type="button" id="home" cnet-button [routerLink]="''" class="ml-0 menu-not-mobile btn-options">
            {{ 'home' | translate}}
          </button>
          <button type="button" id="blog" cnet-button class="ml-0" (click)="openLink(hasBlog())"
            [routerLink]="hasBlog()" *ngIf="hasBlog()">
            {{ 'blog' | translate}}
          </button>
          <button *ngIf="!hideLoginBtn" id="entrar" type="button" cnet-stroked-button size="small"
            (click)="navigateToLogin()" class="mt-3 ml-4 mb-3 btn-login">
            {{ 'entrar' | translate}}
          </button>

          <div class="language-switcher" *ngIf="showLanguageOption()">
            <app-language-switcher></app-language-switcher>
          </div>
        </span>

      </div>

    </cnet-toolbar>

    <cnet-menu #menuOtherOptions="cnetMenu"  cnet-remove-on-scroll >
      <ng-container *ngFor="let route of routesHeaderBreaks">
        <ng-container *ngxPermissionsExcept="route.data.permissions?.except">
          <div>
            <a type="button" [id]="route.data.name" cnet-menu-item [routerLink]="route.routerLink"
              *ngxPermissionsOnly="route.data.permissions?.only" class="btn-cnet-menu menu-not-mobile"
              (click)="menuOtherOptions.hide()">
              <cnet-icon [name]="route.data.icon" label="route.data.icon"></cnet-icon>
              {{route.data.name | translate}}
            </a>
          </div>
        </ng-container>
      </ng-container>
    </cnet-menu>

    <cnet-menu id="profileMenu" class="cnet-profile-menu" cnet-overlay cnet-remove-on-scroll #profileMenu="cnetMenu">
      <app-profile-menu-card [menu]="profileMenu" [routesProfileMenu]="routesProfileMenu" [userDetails]="userDetails"
        (logoutEvent)="test()">
      </app-profile-menu-card>
    </cnet-menu>
  </div>

  <div [hidden]="!isMobile" #toolbar>

    <cnet-toolbar class="toolbar-mobile" color="secondary">

      <!-- logged -->
      <span *ngIf="user" style="display: contents;">

        <div id="cnet-sidenav-open" class="cnet-profile-menu-avatar m-0" [cnet-sidenav-trigger]="sidenav"
          *ngIf="userDetails">
          <cnet-avatar cnet-button class="user-avatar logout dropdown-toggle" size="normal"
            [src]="userDetails?.urlPhoto" [alt]="userDetails.shortName" [text]="userDetails.shortName"></cnet-avatar>
          <cnet-icon name="angle-down"></cnet-icon>
        </div>

        <cnet-sidenav #sidenav="cnetSidenav" backdrop-close animationStartPosition="'left'">
          <app-profile-menu-card [isMobile]="true" [routesProfileMenu]="routesProfileMenu" [userDetails]="userDetails"
            (logoutEvent)="test()">
          </app-profile-menu-card>
        </cnet-sidenav>


        <div [routerLink]="''" class="img-logo img-logo-responsive">
          <img class="portal-logo" [src]="config.portal.darkLogoUrl" [alt]="config.portal.name">
        </div>

        <div class="cnet-profile-bell " [cnet-notifications-trigger]="cnetNotification" style="margin-left: 0;">

          <ng-container>
            <cnet-notifications-bell id="cnet-notification-bell" #cnetNotification="cnetNotification"
              [size]="notifications?.length" (click)="getNotifications()">
              <ng-template ngIf="notifications?.length">
                <div>
                  <div cnet-menu-item *ngFor="let item of notifications" (click)="navigateToRoute(item.url)"
                    class="notification-item">
                    <div [innerHtml]="item.description | translate:resolveParams(item)"></div>
                  </div>
                </div>
              </ng-template>
            </cnet-notifications-bell>
          </ng-container>

        </div>
        <a style="width: 54px" class="cnet-profile-menu-avatar cnet-profile-responsive-menu m-0"
          [routerLink]="'settings'" routerLinkActive="is-active" style="color: white; text-decoration: none;"
          *ngxPermissionsOnly="[
            'SettingsAgreementModuleAccess',
            'SettingsInternshipModuleAccess',
            'SettingsSystemAccess',
            'SettingsEvaluationModuleAccess',
            'SettingsOpportunityModuleAccess',
            'SettingsDocumentAccess',
            'SettingsInsuranceAccess',
            'SettingsUserPermission',
            'SettingsGroupPermission',
            'SettingsPortalLayoutAccess',
            'InstitutionTeamVisualize']">
          <cnet-icon [size]="2" style="padding-right: 4px;" [name]="'cog'">
          </cnet-icon>
        </a>
      </span>

      <div class="img-logo" [routerLink]="''" *ngIf="!user">
        <img class="portal-logo" [src]="config.portal.darkLogoUrl" [alt]="config.portal.name">
      </div>

      <ng-container *ngIf="!user">
        <div class="m-0" [cnet-sidenav-trigger]="cnetSidenavLogedOut">
          <cnet-icon name="bars">
          </cnet-icon>
        </div>

      </ng-container>

    </cnet-toolbar>

    <cnet-toolbar class="toolbar-mobile toolbar-mobile-navigation" color="secondary" *ngIf="user">

      <div #headerOptionsMobile class="d-flex flex-row justify-content-center"
        style="margin-left: auto; overflow: hidden; width: 100vw;">
        <ng-container *ngIf="user">
          <!-- <ng-container *ngFor="let route of routesHeader | slice:0:maxItensHeader;"> -->
          <ng-container *ngFor="let route of routesHeader ">
            <ng-container *ngxPermissionsExcept="route.data.permissions?.except">
              <div #menuMobileOption *ngIf="route.visible">
                <button [id]="route.data.name" type="button" cnet-button [routerLink]="route.routerLink"
                  routerLinkActive="is-active" *ngxPermissionsOnly="route.data.permissions?.only"
                  class="ml-0 btn-options responsive-button" (click)="menuOtherOptions.hide(); test()">
                  <div class="responsive-button-content">
                    <cnet-icon [name]="route.data.icon" label="route.data.icon"></cnet-icon>
                    <div class="label-btn-mobile">
                      {{route.data.name | translate}}
                    </div>
                  </div>
                </button>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="user">
          <div id="cnet-profile-menu-open" *ngIf="routesHeaderBreaks.length" style="width: 54px"
            class="cnet-profile-menu-avatar cnet-profile-responsive-menu m-0" [cnet-menu-trigger]="menuOtherOptions">
            <cnet-icon name="bars">
            </cnet-icon>
          </div>
        </ng-container>
      </div>

    </cnet-toolbar>

    <cnet-sidenav [hidden]="true" #cnetSidenavLogedOut="cnetSidenav" backdrop-close [elementRef]="toolbar"
      [hasBackdrop]="false" animationStartPosition="right">
      <app-sandwitch-menu>
        <button type="button" id="home" cnet-button [routerLink]="'/'" class="ml-0 btn-options" cnet-sidenav-close>
          {{ 'home' | translate}}
        </button>
        <button id="blog" type="button" cnet-button class="ml-0" (click)="openLink(hasBlog())" [routerLink]="hasBlog()"
          *ngIf="hasBlog()" routerLinkActive="is-active" cnet-sidenav-close>
          {{ 'blog' | translate}}
        </button>
        <div id="language-switcher" class="language-switcher" *ngIf="showLanguageOption()">
          <app-language-switcher></app-language-switcher>
        </div>
        <button id="entrar" type="button" cnet-button cnet-filled-button class="ml-0 btn-options" [routerLink]="'auth'"
          cnet-sidenav-close color="primary">
          {{ 'entrar' | translate}}
        </button>
      </app-sandwitch-menu>
    </cnet-sidenav>

  </div>

</ng-container>
