import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeEN from '@angular/common/locales/en';
import localeENGB from '@angular/common/locales/en-GB';
import localeESES from '@angular/common/locales/es';
import localeFRFR from '@angular/common/locales/fr';
import localeITIT from '@angular/common/locales/it';
import localePTBR from '@angular/common/locales/pt';
import localeENAE from '@angular/common/locales/en-AE';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { LanguageService } from '../services/language.service';


export const locales = ['en', 'en-GB', 'pt-BR', 'es-ES', 'it-IT', 'fr-FR', 'en-AE'];
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {

   console.error(`Missing translation for: ${params.key}`);
  }
}

export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super('');
  }

  toString() {
    return this.service.currentLang;
  }
}

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    })
  ],
  exports: [
    TranslateModule
  ],
  providers: [
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
    { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales } }
  ],
})
export class I18nModule {
  constructor(
    private languageService: LanguageService
  ) {

    registerLocaleData(localeENGB, 'en-GB');
    registerLocaleData(localePTBR, 'pt-BR');
    registerLocaleData(localeESES, 'es-ES');
    registerLocaleData(localeITIT, 'it-IT');
    registerLocaleData(localeFRFR, 'fr-FR');
    registerLocaleData(localeENAE, 'en-AE');
    registerLocaleData(localeEN, 'en');

    languageService.setInitialLanguage();

  }
}
