import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { StartupService } from '../services/startup.service';
import { requestUrlPhoto } from '../services/users-profile-pics.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public auth: AuthService,
    public _startupService: StartupService,
    protected _router: Router,
    protected activatedRoute: ActivatedRoute) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let copyReq;

    if (!request.url.includes('startup-service') && !request.url.includes('accessed-page-log') && !request.url.includes('viacep')) {
      //reseta hasCorsErrorOnRequestDifferentThanStartup uma vez q eu guardo essa informação somente da ultima requisição
      this._startupService.hasCorsErrorOnRequestDifferentThanStartup = false;
    }

    if (request.url.includes('AWSAccessKeyId')) {
      // if is s3 url
      copyReq = request.clone({})
      return next.handle(copyReq).pipe(catchError(x => this.handleAuthError(x)));
    }
    copyReq = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getCurrentToken()}`
      }
    });
    return next.handle(copyReq).pipe(catchError(x => this.handleAuthError(x)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // tratamento para o request de photos de usuário "api/user/url-photo" nesse caso caso aconteça um forbiden, só não vou mostrar a imagem
    if (!err.url.includes(requestUrlPhoto)) {

      if (err.status === HttpStatusCode.Unauthorized) {
        this.auth.logoutAndRedirectToLogin();

        return of(err.message);
      }

      if (err.status === HttpStatusCode.Forbidden) {
        this._router.navigate(['/unauthorized']);
        return of(err.message);
      }
    }

    if (err.status === 0 && !err.url.includes('startup-service') && !err.url.includes('accessed-page-log') && !err.url.includes('viacep')) {

      //caso tenha acontecido um erro de cors que não foi do startup service eu guardo essa informação na variavel hasCorsErrorOnRequestDifferentThanStartup
      this._startupService.hasCorsErrorOnRequestDifferentThanStartup = true;

      if (!window.location.href.includes('maintenance')) {
        this._router.navigate(['/maintenance']);
      }
    }

    throw err;
  }
}
