import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { MainPageService } from 'src/app/core/services/main-page.service';
import { StartupService } from 'src/app/core/services/startup.service';


@Injectable({
  providedIn: 'root'
})
export class LoginGuard {

  constructor(
    private authService: AuthService,
    protected router: Router,
    private startupService: StartupService,
    private mainPageService: MainPageService
  ) { }

  canActivate(
    routeSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!routeSnapshot.data.skipLoginGuard) {
      return this.validate(routeSnapshot);
    }
    return true;
  }
  canActivateChild(
    routeSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!routeSnapshot.data.skipLoginGuard) {
      return this.validate(routeSnapshot);
    }
    if (routeSnapshot.url[0].path === 'manager' && this.authService.isLoggedIn()) {
      const urlToRedirect = this.mainPageService.getReturnUrl();
      this.router.navigate([urlToRedirect]);
    }
    return true;
  }

  validate(routeSnapshot: ActivatedRouteSnapshot) {
    if (this.authService.isLoggedIn()) {

      const urlToRedirect = this.mainPageService.getReturnUrl();
      this.router.navigate([urlToRedirect]);
      return false;

    } else {

      // se o usuário não tem o token no local storage isso significa que não está logado no spa, porem ele poderia estar logado no legado. Essa linha garante q se ele estiver
      // logado no legado, ele deve deslogar
      this.authService.logout();

      if (this.startupService.hasCSMIntegration()) {

        if (routeSnapshot?.url[0]?.path) {
          window.open(`${this.startupService.getSymplicityUrl()}/${routeSnapshot.url[0].path === 'institution' ? 'faculty' : routeSnapshot.url[0].path}`, '_self');
        } else {
          window.open(this.startupService.getSymplicityUrl(), '_self');
        }
        return false;
      }
    }

    return true;
  }

}
