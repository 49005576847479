export enum enumTranslatePublicUrlLegacy {
  'vagas' = 'open-jobs',
  'comuns/trocasenhausuario.aspx' = 'change-password',
  'estudante/habilitarconta.aspx' ='enable-account',
  'convite-usuario' = 'invite-user',
  'confere-convite' = 'invite-user',
  'cadastro/conviteusuario.aspx' = 'invite-user',
  'cadastro-empresa' = 'invite',
  'comuns/cancelaremail.aspx' = 'unsubscribe',
  'vaga/visualizar.aspx' = 'jobs/view',
  'privatecv' = 'privatecv',
  'cv' = 'cv',
  'cadastro-login' = 'auth',
  'login.aspx' = 'auth',
  'estagios/gestao' = 'followup',
  'documentacao/api/user'='api-documentation'
}
