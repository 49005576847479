export enum enumTranslateUrlLegacy {
  'candidato' = 'panel',
  'estudante/vagas' = 'jobs',
  'estudante/curriculos' = 'curriculum',
  'estudante/curriculos/visualizarcurriculo.aspx' = 'curriculum-view',
  'estudante/empresa' = 'jobs',
  'estagio' = 'internships',
  'estagios' = 'internships',
  'estagios/visualizar.aspx' = 'internships/view',
  'estagio/documento' = 'document',
  'estagios/documentos' = 'document',
  'm/estagio' = 'internships',
  'm/estagio/auditoria/documento' = 'document',
  'm/estagio/auditoria' = 'internship/audit',
  'm/estagio/contrato-de-estagio/invalidar-formulario' = 'internship/invalidate-contract',
  'portal/candidatos' = 'candidates',
  'portal/empresas' = 'companies',
  'm/documentos/empresa' = 'documents',
  'm/convenio' = 'agreements',
  'vaga' = 'jobs',
  'vagas' = 'open-jobs',
  'campanhas' = 'campaign',
  'instituicao' = 'panel',
  'instituicao/estudante' = 'integration',
  'm/instituicao' = 'campus',
  'instituicao/equipe' = 'team',
  'instituicao/relatorios' = 'reports',
  'configuracoes/relatorios' = 'reports',
  'preferencias' = 'preference',
  'empresa' = 'panel',
  'curriculos' = 'candidates',
  'configuracoes/conta/senha-digital' = 'digital-password',
  'support/privacy-policy-pt' = 'privacy-policy',
  'comuns/dadosusuario.aspx' = 'personal-data',
  'cadastro-vaga' = 'jobs',
  'contratacao/empresa' = 'jobs',
  'empresa/dadosempresa.aspx' = 'company-data',
  'vaga-emprego' = 'jobs',
  'vaga-estagio' = 'jobs',
  'vaga-jovem-aprendiz' = 'jobs',
  'operacional' = 'management',
  'documentacao/gerarcartaz.aspx' = 'document',
  'instituicao/vagas' = 'jobs',
  'contratacao' = 'jobs',
  'cadastrar-vaga' = 'jobs',
  'editar-vaga' = 'jobs',
  'empresa/painel.aspx' = 'panel',
  'admin/gerenciamento' = 'management',
  'buscas/vagas.aspx' = 'jobs',
  'documentacao/templateacompanhamento.aspx' = 'monitoring-document',
  'documentacao/acompanhamentorespondido.aspx' = 'monitoring-document',
  'relatorios' = 'reports',
  'buscas/curriculo.aspx' = 'candidates',
  'operacional/validacao' = 'validation',
  'admin/integracao' = 'yduqs-integration',
  'admin/gerenciamento/masteruseryduqs.aspx' = 'yduqs-integration',
  'operacional/sine' = 'sine',
  'admin/utilitarios' = 'utility',
  'vaga/cadastrar.aspx' = 'jobs',
  'administracao/utilitarios' = 'utility',
  'admin/importacao' = 'import',
  'administracao/servicos' = 'services',
  'administracao/atendimento' = 'support',
  'administracao/manutencao' = 'management',
  'administracao' = 'management',
  'm/estagio/assinatura/advisor' = 'panel',
  'instituicao/estudante/aprovacaoestudante.aspx' = 'student-approval',
  
}
