import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuestionBase } from '@cnet/ngx-ui';
import { Observable } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { enumStatus } from 'src/app/core/enums/enumStatus';
import { enumInternshipType } from 'src/app/core/enums/internshipType';
import { CnetEvaluationDto } from 'src/app/core/models/cnet-evaluation';
import { CnetEvaluationActionDto } from 'src/app/core/models/cnet-evaluation-action';
import { CnetEvaluationConfigDto } from 'src/app/core/models/cnet-evaluation-config';
import { CnetEvaluationDocumentSignatureResponse } from 'src/app/core/models/cnet-evaluation-document-signature-response';
import { CnetEvaluationResult } from 'src/app/core/models/cnet-evaluation-result';
import { CnetEvaluationSurveyDto } from 'src/app/core/models/cnet-evaluation-survey';
import { CnetFollowUpEvaluationDto } from 'src/app/core/models/cnet-follow-up-evaluation';
import { CnetPagedList } from 'src/app/core/models/cnet-paged-list';
import { RestHelperService } from 'src/app/core/services/rest-helper.service';
import { environment } from 'src/environments/environment';
import { CardModel } from '../../shared/components/info-card-components/info-card/model/card.model';


@Injectable({
  providedIn: 'root'
})
export class EvaluationService {

  private evaluationConfigs$: Observable<Array<CardModel<CnetEvaluationConfigDto>>>;

  constructor(
    private http: HttpClient,
    private restHelper:RestHelperService
  ) { }

  getEvaluationConfig(): Observable<Array<CardModel<CnetEvaluationConfigDto>>> {

    if (!this.evaluationConfigs$) {
      this.evaluationConfigs$ =
        this.http.get<Array<CnetEvaluationConfigDto>>(`${environment.apiUrl}/evaluation-config`)
          .pipe(

            catchError(error => {
              throw new Error(error)
            }),
            map(configs => {
              if (configs) {
                let cards: Array<CardModel<CnetEvaluationConfigDto>>;
                cards = configs.map(conf => new CardModel<CnetEvaluationConfigDto>(conf.code, conf.name, conf.description, conf.statusCode, conf))
                return cards;
              }
              return [];
            }),
            share()
          );
    }

    return this.evaluationConfigs$;
  }

  watchMandatoryEvaluationConfigs() {
    return this.getEvaluationConfig().pipe(
      map(config => {
        const mandatoryInternships = config.filter(config => config.data.internshipTypeCode === enumInternshipType.Mandatory || config.data.internshipTypeCode === enumInternshipType.All)
        return mandatoryInternships;
      })
    )
  }

  watchNotMandatoryEvaluationConfigs() {
    return this.getEvaluationConfig().pipe(
      map(config => {
        const notMandatoryInternships = config.filter(config => config.data.internshipTypeCode === enumInternshipType.NotMandatory || config.data.internshipTypeCode === enumInternshipType.All)
        return notMandatoryInternships;
      })
    )
  }

  getEvaluationConfigByCode(code) {
    return this.http.get<CnetEvaluationConfigDto>(`${environment.apiUrl}/evaluation-config/${code}`);
  }

  createEvaluationConfig(body: CnetEvaluationConfigDto) {
    return this.http.post(`${environment.apiUrl}/evaluation-config`, body);
  }

  updateEvaluationConfig(body: CnetEvaluationConfigDto) {
    return this.http.put(`${environment.apiUrl}/evaluation-config`, body);
  }

  activateEvaluationConfig(body: CnetEvaluationConfigDto) {
    return this.http.post(`${environment.apiUrl}/evaluation-config/activate`, body);
  }
  inactivateEvaluationConfig(body: CnetEvaluationConfigDto) {
    return this.http.post(`${environment.apiUrl}/evaluation-config/inactivate`, body);
  }
  deleteEvaluationConfig(code) {
    return this.http.delete(`${environment.apiUrl}/evaluation-config/${code}`);
  }
  createnewversion(code) {
    return this.http.post(`${environment.apiUrl}/evaluation-config/create-new-version`, { code });
  }

  getEvaluationConfigQuestionsByEvaluationConfigCode(code: string) {
    return this.http.get<Array<QuestionBase<any>>>(`${environment.apiUrl}/evaluation-config-question/evaluation-config/${code}`);
  }
  createEvaluationConfigQuestion(body: QuestionBase<any>) {
    return this.http.post(`${environment.apiUrl}/evaluation-config-question`, body);
  }
  updateEvaluationConfigQuestion(body: QuestionBase<any>) {
    return this.http.put(`${environment.apiUrl}/evaluation-config-question`, body);
  }
  deleteEvaluationConfigQuestion(body: any) {
    return this.http.delete(`${environment.apiUrl}/evaluation-config-question`, { body });
  }
  reorderEvaluationConfigQuestion(body: any) {
    return this.http.post(`${environment.apiUrl}/evaluation-config-question/reorder`, body);
  }

  getEvaluations(pageIndex, filters?) {

    const body = this.restHelper.removeEmptyValuesFromObject(filters)
    return this.http.post<CnetPagedList<CnetEvaluationResult>>(`${environment.apiUrl}/evaluation/search`, {...body,pageIndex:(Math.round(pageIndex / 10)) + 1,pageSize:10})
  }


  getEvaluationDocumentByEvaluationCode(evaluationCode) {
    return this.http.get<CnetEvaluationDocumentSignatureResponse>(`${environment.apiUrl}/evaluation/${evaluationCode}/document`);
  }

  getFollowUpsByEvaluationCode(evaluationCode) {
    return this.http.get<HttpResponse<Array<CnetFollowUpEvaluationDto>>>(`${environment.apiUrl}/evaluation/${evaluationCode}/follow-up`, { observe: 'response' }).pipe(
      map(response => {
        if (response.status === 204) {
          return [];
        }
        return response.body;
      }),
      share()
    );
  }

  watchTargetInternFollowUpsByEvaluationCode(evaluationCode) {
    return this.getFollowUpsByEvaluationCode(evaluationCode).pipe(
      map(config => {
        if (config instanceof Array) {
          const mandatoryInternships = config.filter(config => config.target === 'Intern')
          return mandatoryInternships;
        }
        return [];
      })
    )
  }

  watchTargetSupervisorFollowUpsByEvaluationCode(evaluationCode) {
    return this.getFollowUpsByEvaluationCode(evaluationCode).pipe(
      map(config => {
        if (config instanceof Array) {
          const mandatoryInternships = config.filter(config => config.target === 'Supervisor')
          return mandatoryInternships;
        }
        return [];
      })
    )
  }

  getEvaluationByEvaluationCode(evaluationCode): Observable<CnetEvaluationDto> {
    return this.http.get<CnetEvaluationDto>(`${environment.apiUrl}/evaluation/${evaluationCode}`)
  }
  getActionsByEvaluationCode(evaluationCode): Observable<CnetEvaluationActionDto> {
    return this.http.get<CnetEvaluationActionDto>(`${environment.apiUrl}/evaluation/${evaluationCode}/actions`)
  }

  reply(answer: CnetEvaluationSurveyDto) {
    return this.http.post(`${environment.apiUrl}/evaluation/reply`, answer);
  }

  onUpload(fileInfos) {
    const formData = new FormData();
    formData.append('File', fileInfos.file);
    formData.append('EvaluationCode', fileInfos.evaluationCode);
    formData.append('QuestionCode', fileInfos.questionCode);

    return this.upload(formData);

  }

  public upload(formData) {
    return this.http.post<any>(`${environment.apiUrl}/evaluation/reply/question-file`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  deleteFile(fileInfo: { filePath: string, questionCode: string, evaluationCode: string }) {
    return this.http.delete(`${environment.apiUrl}/evaluation/reply/question-file`, { body: fileInfo });
  }

  getDateSubtitle(status, deadLineOn) {

    let message = ''
    switch (status) {
      case enumStatus.Concluded:
        message = 'Concluído em: ';
        break;
      case enumStatus.Pending:
        message = 'Data limite para avaliação: ';
        break;
      case enumStatus.Impeded:
        message = 'Data limite para avaliação: ';
        break;
      case enumStatus.Approved:
        message = 'Aprovado em: ';
        break;
    }

    return { message: message, params: { date: deadLineOn } };
  }

}


