import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { CnetColor, CnetColorPalette } from '@cnet/ngx-ui';
import { StatusType } from 'src/app/core/enums/enumStatus';
import { SharedModule } from 'src/app/shared/shared.module';
import { CardModel } from './model/card.model';

@Component({
  selector: 'app-info-card',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
  host: {
    class: 'cnet-info-card',
    '[class.show-descriptions-on-hover]': 'showDescriptionOnHover',
    '[class.status]': 'status',
    '[class.cnet-color-success]': 'color == "success"',
    '[class.cnet-color-danger]': 'color == "danger"',
    '[class.cnet-color-warning]': 'color == "warning"',
    '[class.cnet-color-info]': 'color == "info"',
    '[class.cnet-color-primary]': 'color == "primary"',
    '[class.cnet-color-secondary]': 'color == "secondary"',
    '[class.cnet-color-cnet-primary]': 'color == "cnet-primary"'
  }
})
export class InfoCardComponent implements OnInit {

  @Input() id;
  @Input() cardInfo!: CardModel<any>;
  @Input() status: null | StatusType = null;
  @Input() showDescriptionOnHover = false;
  @Input() showDescription = true;
  @Input() color: CnetColor | CnetColorPalette = 'default';
  @Input() titleTemplate: TemplateRef<any> = null;
  @Input() descriptionTemplate: TemplateRef<any> = null;
  @Input() loading = false;
  @Input() title = this.cardInfo?.title;
  @Input() description = this.cardInfo?.description;
  @Input() backgroundImage = '';


  @Input("is-link") isLink = true;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('click', ['$event'])
  showMenu(event: MouseEvent) {
    this.clickEvent.next(this.id);
  }

}
