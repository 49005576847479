import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccessedPageLogRequest } from '../models/cnet-accessed-page-log-request';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AcessedPageLogService {

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute, 
    private authService: AuthService) { }


  logPage() {

    if(this.authService.isLoggedIn())
    {
      try
      {
        const request = new AccessedPageLogRequest()
        request.page = window.location.pathname

        const queryParams = this.activatedRoute.snapshot.queryParams;
        request.queryParams = queryParams ? Object.keys(queryParams).filter(key => key !== 'credential').map(key => { return { key: key, value: queryParams[key] } }) : []

        this.http.post(`${environment.apiUrl}/user/accessed-page-log`, request).subscribe();
      }
      catch{
      }
    }
  }
}
