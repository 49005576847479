import { Component, OnInit } from '@angular/core';

@Component({
  template: `
    <app-error-details title="{{ 'Oops! Não foi possivel entrar em sua conta.' | translate }}"
      description="{{ 'Não foi possivel entrar com suas credenciais. Se você encontrou algum problema, comunique-nos.' | translate }}"
      [date]="date">
    </app-error-details>
`,
  styleUrls: ['./invalid-token.component.scss']
})

export class InvalidTokenComponent {
  title = 'Oops! Não foi possivel entrar em sua conta.';
  description = 'Não foi possivel entrar com suas credenciais. Se você encontrou algum problema, comunique-nos.';
  date = new Date();
}
