import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss'],
  host: {
    class: 'card-page-content',
    '[class.hasNoMarginBottom]': 'hasMargin'
  },
})
export class PageContentComponent implements OnInit {

  @Input() title: null | string = null;
  @Input() subTitle: null | string = null;
  @Input() titleTemplate: TemplateRef<any> = null;
  @Input() subTitleTemplate: TemplateRef<any> = null;
  @Input() footerTemplate: TemplateRef<any> = null;
  @Input() showFooter = true;
  @Input() actionsTemplate: TemplateRef<any> = null;
  @Input() isLoading: boolean = false;
  @Input() hasPadding: boolean = true;
  @Input() hasMargin: boolean = true;
  @Input() hasBackground: boolean = true;
  @Input() hasBorder: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

}
