export class RouteAux {
  contextPath: string;
  path: string;
  data: any;
  routerLink: string;
  visible: boolean;

  constructor(contextPath: string, path: string, data: any, routerLink: string, visible: boolean = true) {
    this.contextPath = contextPath;
    this.path = path;
    this.data = data;
    this.routerLink = routerLink;
    this.visible = visible;
  }

}
