import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CnetConfirmDialogModule, CnetNotificationsBellModule } from '@cnet/ngx-ui';
import { CookieService } from 'ngx-cookie-service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GoogleAnalyticsService } from './core/analytics/google-analytics.service';
import { I18nModule } from './core/i18n/i18n.module';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { AuthService } from './core/services/auth.service';
import { InitEnviromentService } from './core/services/init-enviroment.service';
import { StartupService } from './core/services/startup.service';
import { UiService } from './core/services/ui.service';
import { ConfigTesterComponent } from './shared/components/config-tester/config-tester.component';
import { ErrorDetailsModule } from './shared/components/error-details/error-details.module';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderToolbarModule } from './shared/components/header-toolbar/header-toolbar.module';
import { ProfileMenuCardModule } from './shared/components/profile-menu-card/profile-menu-card.module';
import { IframeErrorComponent } from './shared/pages/iframe-error/iframe-error.component';
import { InternalServerErrorComponent } from './shared/pages/internal-server-error/internal-server-error.component';
import { InvalidCompanyComponent } from './shared/pages/invalid-company/invalid-company.component';
import { InvalidTokenComponent } from './shared/pages/invalid-token/invalid-token.component';
import { MaintenanceComponent } from './shared/pages/maintenance/maintenance.component';
import { NotFoundComponent } from './shared/pages/not-found/not-found.component';
import { UnauthorizedComponent } from './shared/pages/unauthorized/unauthorized.component';
import { SharedModule } from './shared/shared.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

export function initFunction(
  initEnviroment: InitEnviromentService) {
  return async () => {
    await initEnviroment.init();

  };
}

export const CNET_APP_INITIALIZER = {
  provide: APP_INITIALIZER,
  useFactory: initFunction,
  deps: [InitEnviromentService, StartupService, UiService, CookieService, GoogleAnalyticsService],
  multi: true
};

@NgModule({
  declarations: [
    AppComponent,
    ConfigTesterComponent,
    NotFoundComponent,
    IframeErrorComponent,
    UnauthorizedComponent,
    InternalServerErrorComponent,
    InvalidTokenComponent,
    InvalidCompanyComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    I18nModule,
    CnetNotificationsBellModule,
    ProfileMenuCardModule,
    HeaderToolbarModule,
    NgxPermissionsModule.forRoot(),
    ErrorDetailsModule,
    CnetConfirmDialogModule,
    FooterComponent,
    NgIdleKeepaliveModule.forRoot(),
    MaintenanceComponent
  ],
  providers: [
    InitEnviromentService,
    StartupService,
    CookieService,
    CNET_APP_INITIALIZER,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.mim.js' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private _authService: AuthService
  ) {
    if (_authService.isLoggedIn()) {
      _authService.reloadUser();
    }
  }
}
