import { computed, Directive, effect, ElementRef, EventEmitter, HostListener, input, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, throttleTime, debounceTime, tap, of, Subject, Subscription } from 'rxjs';

@Directive({
  selector: '[click.throttle]',
  standalone: true
})
export class ClickThrottleDirective implements OnInit, OnDestroy {

  idleTime = input(1500);
  @Output('click.throttle') throttleClick = new EventEmitter();
  private clicks = new Subject();
  private subscription: Subscription;

  constructor() { }

  ngOnInit() {
      this.subscription = this.clicks
      .pipe(throttleTime(this.idleTime()))
      .subscribe(e => this.throttleClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    this.clicks.next(event);
  }

}


@Directive({
  selector: '[click.debounce]',
  standalone: true
})
export class ClickDebounceDirective implements OnInit, OnDestroy {

  idleTime = input(1500);

  @Output('click.debounce') debounceClick = new EventEmitter();
  private clicks = new Subject();
  private subscription: Subscription;

  constructor() { }

  ngOnInit() {
    this.subscription = this.clicks
    .pipe(debounceTime(this.idleTime()))
    .subscribe(e => this.debounceClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    this.clicks.next(event);
  }

}
