import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RestHelperService {

  convertObjectToHttpParams(obj: Object) {
    let params = new HttpParams();
    if (typeof obj === 'object' && obj) {
      Object.getOwnPropertyNames(obj).forEach(
        objPropertyName => {
          if (obj[objPropertyName]) {
            if (Array.isArray(obj[objPropertyName])) {
              obj[objPropertyName].forEach(value => {
                params = params.append(objPropertyName, value.toString().trim());
              });
            } else {
              if (obj[objPropertyName] instanceof Date) {
                const date = new Date(obj[objPropertyName]);

                const dateWithouTimezone = this.toISOStringWithoutTimezone(date);
                params = params.append(objPropertyName, dateWithouTimezone);
              } else {
                params = params.append(objPropertyName, obj[objPropertyName].toString().trim());
              }
            }
          }
        }
      )
    } else {
      console.error('obj is not an object')
    }
    return params;
  }

  toISOStringWithoutTimezone(date: Date): string {
    if (date == null) return '';

    const timeStamp = date.getTime() - date.getTimezoneOffset() * 60000;
    const correctDate = new Date(timeStamp);

    return correctDate.toISOString();
  }

  removeEmptyValuesFromObject(obj){
    if(obj){
      Object.keys(obj).forEach(key => {
        if (obj[key] === null || obj[key] === '' || (Array.isArray(obj[key]) && !obj[key].length)) {
          delete obj[key];
        }
      });
      return obj;
    }else{
      return {}
    }
  }

}
