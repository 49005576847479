<ng-container *ngIf="!isMobile; else elseTemplate">
  <div class="card">
    <div class="header">
      <div class="prof-image">
        <cnet-avatar cnet-button class="user-avatar logout dropdown-toggle" size="large" [src]="userDetails?.urlPhoto"
          [alt]="userDetails?.shortName" [text]="userDetails?.shortName"></cnet-avatar>
      </div>
      <div class="info">
        <div class="title">{{user?.short_name}}</div>
        <div *ngIf="showLanguageOption() || isUserRoleCnetAdmin">
          <app-language-switcher></app-language-switcher>
        </div>
      </div>
    </div>
    <div class="options">
      <ng-container *ngIf="routesProfileMenu?.length">
        <cnet-divider style="padding-bottom: 8px;"></cnet-divider>
        <ng-container *ngFor="let route of routesProfileMenu">
          <div>
            <a [id]="route.data.name" type="button" cnet-menu-item [routerLink]="route.routerLink"
              *ngxPermissionsOnly="route.data.permissions.only" (click)="hideMenu()" class="menu-option">
              {{route.data.name | translate}}
            </a>
          </div>
        </ng-container>
      </ng-container>

      <cnet-divider style="margin-top: 8px; padding-top: 8px;"></cnet-divider>
      <button type="button" cnet-menu-item class="text-danger" (click)="logout()"> {{ 'Sair' | translate}} </button>
    </div>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <cnet-card class="menu-mobile">
    <cnet-card-header>
      <div class="header" cnet-card-title>
        <div class="image">
          <cnet-avatar cnet-button class="user-avatar logout dropdown-toggle" size="large" [src]="userDetails?.urlPhoto"
            [alt]="userDetails?.shortName" [text]="userDetails?.shortName"></cnet-avatar>
        </div>
        <div class="info">
          <div class="title">{{user?.short_name}}</div>
          <div *ngIf="showLanguageOption()">
            <app-language-switcher></app-language-switcher>
          </div>
        </div>
      </div>
      <div cnet-card-actions>
        <b type="button" cnet-icon-button cnet-sidenav-close>
          <cnet-icon name="close"></cnet-icon>
        </b>
      </div>
    </cnet-card-header>
    <div class="options">
      <ng-container *ngFor="let route of routesProfileMenu">
        <a [id]="route.data.name" type="button"  class="menu-option" cnet-menu-item [routerLink]="route.routerLink"
          *ngxPermissionsOnly="route.data.permissions.only" cnet-sidenav-close >
          {{route.data.name | translate}}
        </a>
      </ng-container>

      <cnet-divider style="margin-top: 8px; padding-top: 8px;"></cnet-divider>
      <button id="sair" type="button" cnet-menu-item class="text-danger" (click)="logout()" cnet-sidenav-close> {{ 'Sair' |
        translate}} </button>
    </div>

  </cnet-card>
</ng-template>
