import { NgModule } from '@angular/core';
import { CnetSubHeaderContentComponent } from './sub-header-content/sub-header-content.component';
import { SubHeaderComponent } from './sub-header.component';
import { SubHeaderActionsDirective } from './sub-header-actions/sub-header-actions.directive';



@NgModule({
  declarations: [],
  imports: [
    SubHeaderComponent,
    CnetSubHeaderContentComponent,
    SubHeaderActionsDirective
  ],
  exports: [
    SubHeaderComponent,
    CnetSubHeaderContentComponent,
    SubHeaderActionsDirective
  ]
})
export class SubHeaderModule { }
