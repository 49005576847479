import { Injectable } from '@angular/core';
import { CnetToasterService } from '@cnet/ngx-ui';
import { CnetToasterRef } from '@cnet/ngx-ui/lib/components/toaster/toaster-ref';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastAdapter {

  constructor(
    private translateService: TranslateService,
    private toasterService: CnetToasterService,
  ) { }

  open(content: string, time: number = 6000, type?: 'success' | 'danger' | 'default' | 'info', id?: string, params: any = null): CnetToasterRef {
    const message = params ? this.translateService.instant(content, params) : this.translateService.instant(content);
    return this.toasterService.open(message, time, type, id);
  }

  openSuccess(content: string, params: any = null, id?: string, time: number = 6000): CnetToasterRef {
    return this.open(content, time, 'success', id, params);
  }

  openError(content: string, params: any = null, id?: string, time: number = 6000): CnetToasterRef {
    return this.open(content, time, 'danger', id, params);
  }

  openInfo(content: string, params: any = null, id?: string, time: number = 6000): CnetToasterRef {
    return this.open(content, time, 'info', id, params);
  }

  clearAll(): void {
    this.toasterService.clearAll();
  }

}
