<form [formGroup]="languageForm">
  <cnet-select placeholder="Select an option" formControlName="country" #select [viewValueTpl]="labelTpl">

    <ng-template #labelTpl let-label>
      <div style="min-width: 96px;">
        <span class="fi" [class]="'fi-'+ label?.icon"></span>
        {{label?.label | translate}}
      </div>
    </ng-template>

    <cnet-select-option *ngFor="let el of languagesArray" [value]="el" [viewValue]="el" [template]="optionTpl">
      <ng-template #optionTpl let-option>
        <div class="element-template">
          <span class="fi" [class]="'fi-'+ option?.icon"></span>
          {{option.label | translate}}
        </div>
      </ng-template>

    </cnet-select-option>
  </cnet-select>


</form>
