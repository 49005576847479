export class AddressData {
  countryCode: string; //"BR",  //required
  zipCode: string;// "31995150",  //required
  description: string; // "Rua Joao Lemos, 5 B5 Ap50",  //required
  neighborhood: string;// "Acaiaca",
  number: number;
  cityName: string;// string; "Belo Horizonte",  //required
  stateName: string; //"Minas Gerais" // required
  stateAcronym: string; // "MG"
  complement: string
}
