import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalRenderComponent } from './portal-render.component';
import { SanitizerUrlModule } from 'src/app/shared/pipes/sanitizer-url/sanitizer-url/sanitizer-url.module';
import { AppIframeTrackerModule } from 'src/app/shared/directives/app-iframe-tracker/app-iframe-tracker.module';



@NgModule({
  declarations: [PortalRenderComponent],
  exports: [PortalRenderComponent],
  imports: [
    CommonModule,
    SanitizerUrlModule,
    AppIframeTrackerModule
  ]
})
export class PortalRenderModule { }
