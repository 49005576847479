import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { formatNumber } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: "cnetDecimal",
  pure: false
})
export class CnetDecimalPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }

  transform(val: number | string, digitsInfo: string = "1.2") {

    if(val){
      const valAsNumber: number = typeof val === 'string' ? Number.parseFloat(val) : val;

      if (Number.isNaN(valAsNumber)) {
        return val
      }

      const locale = this.translateService.currentLang;
      const str = formatNumber(valAsNumber, locale, digitsInfo);

      return str.replace(/^0+([^\d])/, "");
    }
    return val;
  }
}
