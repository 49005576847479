import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { BasicModel } from 'src/app/core/models/basic-model';
import { InstitutionService } from 'src/app/core/services/institution.service';
import { AbstractFilter } from '../abstract-filter';
import { InstitutionFilterType } from './institution-filter-type';
import { State } from 'src/app/core/models/state';
import { AddressService } from 'src/app/modules/company/components/address-data/address.service';
import { StartupService } from 'src/app/core/services/startup.service';
import { CampusFilterResponse } from 'src/app/core/models/cnet-campus-filter-response';

@Component({
  selector: 'app-institution-filter',
  templateUrl: './institution-filter.component.html',
  styleUrls: ['./institution-filter.component.scss']
})
export class InstitutionFilterComponent extends AbstractFilter<InstitutionFilterType> {
  filterFormGroup: UntypedFormGroup
  institutions$: Observable<Array<BasicModel>> = this.institutionService.getAllInstitutions();
  modalities$: Observable<Array<BasicModel>> = this.institutionService.getModalities();
  campus: Array<CampusFilterResponse> = [];
  campusFiltered: Array<CampusFilterResponse> = [];
  campus$: Observable<Array<CampusFilterResponse>> = this.institutionService.getCampusByInstitutionCode().pipe(tap(campus => { this.campus = campus }));
  courses$: Observable<Array<BasicModel>> = of([]);
  campusStates$: Observable<Array<State>> = this.addressService.getStatesByCountryCode(this.startupService.getRegion());
  coursesNames$ = of([])

  constructor(
    private formBuilder: UntypedFormBuilder,
    private institutionService: InstitutionService,
    private addressService: AddressService,
    private startupService: StartupService) {
    super();
  }

  ngOnInit(): void {
    this.campus$.subscribe();
  }

  createGroup() {
    this.filterFormGroup = this.formBuilder.group({
      InstitutionCodes: ['', []],
      CampusCodes: ['', []],
      CourseCodes: ['', []],
      CourseName: ['', []],
      AdvisorInstitutionName: ['', []],
      Modality: ['', []],
      CampusState: ['', []],
    });

    this.filterFormGroup.controls.CourseCodes.disable();

    this.filterFormGroup.controls.InstitutionCodes.valueChanges
      .pipe(
        debounceTime(1000),
        tap(
          institutionCodes => {
            this.filterFormGroup.controls.CourseCodes.reset();
            this.filterFormGroup.controls.CampusCodes.reset();

            if (institutionCodes == null) return;

            this.campusFiltered = [...this.campus.filter(c => institutionCodes.includes(c.institution))]
            this.courses$ = of([]);


          }
        )
      )
      .subscribe(
    )
    this.filterFormGroup.controls.CampusCodes.valueChanges
      .pipe(
        tap(
          campusCodes => {

            if (campusCodes && campusCodes.length) {
              this.filterFormGroup.controls.CourseCodes.enable();
              this.filterFormGroup.controls.CourseCodes.reset();

              if (this.filterFormGroup.controls.CourseName.enabled) {
                this.filterFormGroup.controls.CourseName.reset();
                this.filterFormGroup.controls.CourseName.disable();
              }

            } else {
              this.filterFormGroup.controls.CourseCodes.disable();
              this.filterFormGroup.controls.CourseName.enable();
            }
          }
        )
      )
      .subscribe();
    this.filterFormGroup.controls.CampusCodes.valueChanges
      .pipe(
        debounceTime(1300),
        tap(
          campusCodes => {

            if (campusCodes == null) return;

            this.courses$ = this.institutionService.getCourseByCampusCode(campusCodes);
          }
        )
      )
      .subscribe();

    this.filterFormGroup.controls.CourseName.valueChanges
      .pipe(
        debounceTime(300),
        tap(
          value => {

            this.coursesNames$ = value && value.length > 2 ? this.institutionService.getCoursesNames(value) : of([]);

          }
        )
      )
      .subscribe()

    return this.filterFormGroup;
  }

}
