<app-page-content [footerTemplate]="footerTemplate" [title]="'Pesquisar avaliações' | translate">

  <form [formGroup]="evaluationSearchForm" class="mt-6">
    <div class="row">
      <cnet-form-field class="col-md-4 col-sm-12">
        <cnet-field-label>{{'Título da avaliação' | translate}}</cnet-field-label>
        <input cnet-input formControlName="evaluationName" type="text"
          [placeholder]="'Digite o título da Avaliação' | translate" />
      </cnet-form-field>
      <cnet-form-field class="col-md-4 col-sm-12">
        <cnet-field-label>{{'Nome do Aluno' | translate}}</cnet-field-label>
        <input cnet-input formControlName="studentName" type="text"
          [placeholder]="'Digite o nome do aluno' | translate" />
      </cnet-form-field>
      <cnet-form-field class="col-md-4 col-sm-12">
        <cnet-field-label>{{'Status' | translate}}</cnet-field-label>
        <cnet-select name="evaluationStatus" formControlName="evaluationStatus" show-filter
          [filterPlaceHolder]="'Busque por um status' | translate" [placeholder]="'Selecione um status' | translate">
          <cnet-select-option value="Impeded" [viewValue]="'Impedido' | translate"></cnet-select-option>
          <cnet-select-option value="Pending" [viewValue]="'Pendente' | translate"></cnet-select-option>
          <cnet-select-option value="Concluded" [viewValue]="'Concluído' | translate"></cnet-select-option>
          <cnet-select-option value="AwaitingSignature" [viewValue]="'Aguardando Assinatura' | translate"></cnet-select-option>
        </cnet-select>
      </cnet-form-field>
    </div>

    <cnet-panel #panel="cnetPanel">
      <div>
        <cnet-tab-group>
          <cnet-tab [tab-title]="'Avaliação' | translate">
            <app-evaluation-filter></app-evaluation-filter>
          </cnet-tab>

          <cnet-tab [tab-title]="'Estágio' | translate">
            <app-internship-filter></app-internship-filter>
          </cnet-tab>

          <cnet-tab [tab-title]="'Instituição'| translate">
            <app-institution-filter></app-institution-filter>
          </cnet-tab>

        </cnet-tab-group>
      </div>
    </cnet-panel>

    <ng-template #footerTemplate>
      <div cnet-card-actions class="d-flex justify-content-end">
        <button type="button" cnet-filled-button color="cancel" class="btn btn-cancel m-2" [cnet-panel-trigger]="panel">
          <cnet-icon *ngIf="panel.expanded" name="angle-double-up"></cnet-icon>
          <cnet-icon *ngIf="!panel.expanded" name="angle-double-down"></cnet-icon>
          {{'Avançado'| translate}}
        </button>

        <button type="button" (click)="search()" cnet-filled-button color="primary" class="btn btn-primary m-2">
          <cnet-icon name="filter"></cnet-icon>
          {{'Pesquisar'|translate}}
        </button>
      </div>
    </ng-template>

  </form>

</app-page-content>


<ng-container *ngIf="evaluations$| async as evaluations; else loading">

  <div *ngIf="evaluations?.subset.length; else notFoundEvaluation">
    <app-page-content
      title="{{ '{0} Avaliações'| translate:{'0':evaluations?.totalItemCount | cnetDecimal : '1.0' }  }}">

      <table cnet-table [dataSource]="evaluations?.subset">

        <ng-container cnetColumnDef="evaluationName">
          <th cnet-header-cell *cnetHeaderCellDef>{{'Título' | translate}}</th>
          <td cnet-cell *cnetCellDef="let element" [datath]="'Título' | translate">{{element.evaluationName}}</td>
        </ng-container>
        <ng-container cnetColumnDef="studentName">
          <th cnet-header-cell *cnetHeaderCellDef>{{'Estudante' | translate}}</th>
          <td cnet-cell *cnetCellDef="let element" [datath]="'Estudante' | translate">{{element.studentName}}</td>
        </ng-container>
        <ng-container cnetColumnDef="studentRegistrationNumber">
          <th cnet-header-cell *cnetHeaderCellDef>{{'Matricula' | translate}}</th>
          <td cnet-cell *cnetCellDef="let element" [datath]="'Matricula' | translate">{{element.studentRegistrationNumber}}</td>
        </ng-container>
        <ng-container cnetColumnDef="campusName">
          <th cnet-header-cell *cnetHeaderCellDef>{{'Campus' | translate}}</th>
          <td cnet-cell *cnetCellDef="let element" [datath]="'Campus' | translate">{{element.campusName}}</td>
        </ng-container>
        <ng-container cnetColumnDef="courseName">
          <th cnet-header-cell *cnetHeaderCellDef>{{'Curso' | translate}}</th>
          <td cnet-cell *cnetCellDef="let element" [datath]="'Curso' | translate">{{element.courseName}}</td>
        </ng-container>
        <ng-container cnetColumnDef="deadLine">
          <th cnet-header-cell *cnetHeaderCellDef>{{'Prazo de resposta' | translate}}</th>
          <td cnet-cell *cnetCellDef="let element" [datath]="'Prazo de resposta' | translate">{{element.deadLine |localizeDatePipe :
            'shortDate'}}
          </td>
        </ng-container>
        <ng-container cnetColumnDef="totalFollowUpAnswered">
          <th cnet-header-cell *cnetHeaderCellDef>{{'Respondidos/Total' | translate}}</th>
          <td cnet-cell *cnetCellDef="let element" [datath]="'Respondidos/Total' | translate" style="text-align: center;">
            {{element.totalFollowUpAnswered  | cnetDecimal : '1.0'}}/{{element.totalFollowUp  | cnetDecimal : '1.0'}}
        </ng-container>
        <ng-container cnetColumnDef="status">
          <th cnet-header-cell *cnetHeaderCellDef>{{'Status' | translate}}</th>
          <td cnet-cell *cnetCellDef="let element" [datath]="'Status' | translate">
            <div cnet-badge cnet-badge-type="flat" class="badge" [cnet-badge-color]="element.status | statusToBadges">
              {{ element.status | statusTypeTranslate}}
            </div>
          </td>
        </ng-container>


        <ng-container cnetColumnDef="actions">
          <th cnet-header-cell class="cnet-cell-right" *cnetHeaderCellDef></th>
          <td cnet-cell class="cnet-cell-right" *cnetCellDef="let element">
            <a cnet-icon-button [size]="2" aria-label="Details"
              [routerLink]="'/evaluation/'+element.code+'/details'">
              <cnet-icon name="search" label="Details"></cnet-icon>
            </a>
          </td>
        </ng-container>

        <tr cnet-header-row *cnetHeaderRowDef="displayedColumns"></tr>
        <tr cnet-row *cnetRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <cnet-pagination [offset]="offset" [limit]="10" [size]="evaluations?.totalItemCount"
        (pageChange)="onPageChange($event)">
      </cnet-pagination>

    </app-page-content>

  </div>



</ng-container>

<ng-template #loading>

  <app-page-content [isLoading]="true">
  </app-page-content>
</ng-template>

<ng-template #notFoundEvaluation>
  <app-page-content [title]="'Avaliações'| translate">
    <app-results-not-found></app-results-not-found>
  </app-page-content>
</ng-template>
