import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { State } from 'src/app/core/models/state';
import { AddressData } from 'src/app/core/models/address-data';
import { BasicModel } from 'src/app/core/models/basic-model';
import { ViaCepResposnse } from 'src/app/core/models/via-cep-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(
    private http: HttpClient,
  ) { }


  getStatesByCountryCode(countryCode: string) {
    return this.http.get<Array<State>>(`${environment.apiUrl}/location/country/${countryCode}/states`)
  }
  getCitiesByStateCode(stateCode: string) {
    return this.http.get<Array<BasicModel>>(`${environment.apiUrl}/location/states/${stateCode}/cities`)
  }

  getAddressByCep(cep) {
    return this.http.get<ViaCepResposnse>(`${environment.viaCepApiUrl}${cep}/json`)
      .pipe(
        map((response: ViaCepResposnse) => {

          if (response.erro) {
            return null
          } else {
            const address = new AddressData();

            address.neighborhood = response.bairro;
            address.description = response.logradouro;
            address.cityName = response.localidade;
            address.stateAcronym = response.uf;

            return address;
          }
        })
      )
  }

}
