import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusToBadgesPipe } from './status-to-badges.pipe';



@NgModule({
  declarations: [StatusToBadgesPipe],
  providers: [StatusToBadgesPipe],
  exports: [StatusToBadgesPipe],
  imports: [
    CommonModule
  ]
})
export class StatusToBadgesModule { }
