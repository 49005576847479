import { Component } from '@angular/core';

@Component({
  template: `
  <app-error-details
      title="{{'Oops! Esta página não existe.' | translate}}"
      description="{{'Não conseguimos encontrar a página que você estava buscando. Se você encontrou algum problema, comunique-nos.' | translate}}">
  </app-error-details>
  `,
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent { }
