import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizeDatePipe',
  pure: false
})
export class LocalizeDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: Date | string, pattern: string = 'mediumDate'): any {
    
    if(value){
      const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
      const newDate = new Date(value)
  
      return datePipe.transform(newDate, pattern);
    }
    return '';
  }

}
