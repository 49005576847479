import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AbstractFilter } from '../abstract-filter';
import { EvaluationFilterType } from './evaluation-filter-type';

@Component({
  selector: 'app-evaluation-filter',
  templateUrl: './evaluation-filter.component.html',
  styleUrls: ['./evaluation-filter.component.scss']
})
export class EvaluationFilterComponent extends AbstractFilter<EvaluationFilterType> {

  filterFormGroup: UntypedFormGroup

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }
  createGroup() {
    this.filterFormGroup = this.formBuilder.group({
      evaluationDeadlineStartDate: ['', []],
      evaluationDeadlineEndDate: ['', []],
      approved: ['', []],
    });

    return this.filterFormGroup;
  }
}
