import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, debounceTime, delay, of, tap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { StartupService } from 'src/app/core/services/startup.service';
import { environment } from 'src/environments/environment';
import { UrlAuthRedirectService } from '../pages/url-auth-redirect/url-auth-redirect.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadReportGuard  {

  router = inject(Router);
  startupService = inject(StartupService)
  authService= inject(AuthService)
  urlAuthRedirectService = inject(UrlAuthRedirectService)

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    this.downloadReport(); 
    this.urlAuthRedirectService.cleanPreviousSrcUrl();
    return this.router.navigate(['/l/institution/admin/reports']);
  }

  downloadReport(){
    const downloadReportRoute = `${environment.httpSchema}://${this.startupService.getLegacyUrl()}/configuracoes/relatorios/download${window.location.search.split('&returnUrl')[0]}`;
    setTimeout(() => {
      window.open(downloadReportRoute);
    }, 2000);
  }
}
