import * as uuid from 'uuid';

export class SelectOptionModel<T> {

  constructor(label?: string, value?: T, id?: string,) {
    if (id) {
      this.id = id;
    }
    if (label) {
      this.label = label;
    }
    if (value) {
      this.value = value;
    }
  }
  id: string = uuid.v4();
  label: string;
  value: T;
  disable?: boolean = false;
  tooltip?: string
}
