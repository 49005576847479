import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(
    private _router: Router,
    private _authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this._authService.isLoggedIn()) {

      const user = this._authService.getUser();
      if (user) {
        if (route.data.roles && !route.data.roles.includes(user.user_role)) {
          this._router.navigate(['/unauthorized']);
          return false;
        }

        if (route.data && route.data.externalUrl) {
          window.open(route.data.externalUrl, '_self');
          return false;
        }

        return true;
      }
    }
    setTimeout(() => {
      this._authService.logoutAndRedirectToLogin();
    }, 100);
    return false;

  }
}
