import { CommonModule } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { CnetNgxUiModule } from '@cnet/ngx-ui';
import { TranslateModule } from '@ngx-translate/core';
import { SubHeaderDetails } from 'src/app/core/models/sub-header-details';
import { PermissionRoleIdTranslatePipe } from 'src/app/shared/pipes/permission-role/permission-role.pipe';

@Component({
  selector: 'app-sub-header-content, [app-sub-header-content], [CnetSubHeaderContent]',
  standalone: true,
  host: { class: 'app-sub-header-content' },
  exportAs: 'CnetSubHeaderContent',
  imports: [CommonModule, CnetNgxUiModule, TranslateModule, PermissionRoleIdTranslatePipe],
  templateUrl: './sub-header-content.component.html',
  styleUrl: './sub-header-content.component.scss',


})
export class CnetSubHeaderContentComponent {

  @Input() details: SubHeaderDetails
  @Input() showPhoto = false
  @Input() showGroups = false
  isMobile = false;

  ngOnInit(): void {
    this.onResize({ target: { innerWidth: window.innerWidth } })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = event.target.innerWidth <= 992;
  }


}
