import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortalRenderService {

  public isRendering: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  getRenderingIframe(): Observable<boolean> {
    return this.isRendering.asObservable();
  }

  public start() {
    this.isRendering.next(true);
  }
  public end() {
    this.isRendering.next(false);
  }
}
