import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { CsmErrorService } from 'src/app/core/services/csm-error.service';
import { ToastAdapter } from 'src/app/core/services/toast-adapter.service';

@Injectable({
  providedIn: 'root'
})
export class SigninSymplicityGuard  {

  errors = [];
  constructor(
    private _router: Router,
    private _csmErrorService: CsmErrorService,
    private _authService: AuthService,
    private _toasteService: ToastAdapter
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this._authService.logout();

    const errorGuid = route.queryParams?.errors;
    return this._csmErrorService.getCsmErrors(errorGuid)
      .pipe(
        first(),
        map((next: any) => {
          if (next) {
            this.errors = next;
            return true;
          }
          this._router.navigate(['auth']);
          return false;
        }),
        catchError(() => {
          this._toasteService.clearAll();
          this._router.navigate(['auth']);
          throw new Error('Um token válido precisa ser enviado');
        })

      );
  }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.errors;
  }

}
