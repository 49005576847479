export type StatusType = 'Pending' | 'Impeded' | 'Approved' | "Active" | "Inactive" | "Removed" | "Developing" | "Outdated" | "Replied" 
| "Late" | "Concluded" | "AwaitingSignature" | "Generated" | "Failed" | "InProcessing";

export enum enumStatus {
  "Pending" = 'Pending',
  "Impeded" ='Impeded',
  "Approved" = 'Approved',
  "Active" = "Active",
  "Inactive" = "Inactive",
  "Removed" = "Removed",
  "Developing" = "Developing",
  "Outdated" = "Outdated",
  "Replied" = "Replied",
  "Late" = "Late",
  "Concluded" = "Concluded",
  "AwaitingSignature" = "AwaitingSignature",
  "Generated" = "Generated",
  "Failed" = "Failed", 
  "InProcessing" = "InProcessing"
}

export enum enumStatusTranslated {
  "Pending" = 'Pendente',
  "Impeded" = 'Impedido',
  "Approved" = 'Aprovado',
  "Active" = "Ativo",
  "Inactive" = "Inativo",
  "Removed" = "Removido",
  "Developing" = "Em desenvolvimento",
  "Outdated" = "Desatualizado",
  "Replied" = "Respondido",
  "Late" = "Atrasado",
  "Concluded" = "Concluído",
  "AwaitingSignature" = "Aguardando Assinatura",
  "Generated" = "Gerado",
  "Failed" = "Falhou", 
  "InProcessing" = "Em Processamento"

}

export enum enumStatusToBadge {
  "Active" = 'success',
  "Replied" = 'success',
  "Inactive" = 'default',
  "Removed" = 'danger',
  "Impeded" = 'danger',
  "Developing" = 'cnet-primary',
  "Outdated" = 'default',
  "Pending" = 'warning',
  "Late" = "danger",
  "Concluded" = "success",
  "AwaitingSignature" = "info",
  "Generated" = "info", 
  "Failed" = 'danger', 
  "InProcessing" = 'cnet-primary'
}
