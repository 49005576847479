import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { StartupService } from 'src/app/core/services/startup.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent implements OnInit {

  languagesArray = [
    { i18n: 'pt-BR', label: 'Português', icon: 'br' },
    { i18n: 'en-GB', label: 'Ingles', icon: 'gb' },
    { i18n: 'es-ES', label: 'Espanhol', icon: 'es' },
    { i18n: 'en-AE', label: 'Inglês Árabe', icon: 'ae' },
  ];

  // { i18n: 'fr-FR', label: 'Francês', icon: 'fr' }
  // { i18n: 'it-IT', label: 'Italiano', icon: 'it' },

  languageForm: UntypedFormGroup;
  @Input() setOnLocalStorage = true;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _translateService: TranslateService,
    private _languageService: LanguageService,
    private _authService: AuthService,
    private _startupService: StartupService
  ) {}

  ngOnInit(): void {
    this.languageForm = this._formBuilder.group({
      country: ['', [Validators.required]],
    });

    this.languageForm.controls.country.valueChanges.subscribe(value => {
      this.setLanguage(value?.i18n);
    });


    this.getLanguage();

  }

  getLanguage() {

    setTimeout(() => {
      const language = this.languagesArray.find(language => language.i18n === this._languageService.changeLanguage.getValue());
      this.languageForm.controls.country.setValue(language, { emitEvent: false });
    },);

  }
  getlanguageFromLocalStorage() {

    if (this._startupService.getDefaultLanguage()) {
      this.setOldLanguageForm(this._startupService.getDefaultLanguage());
    } else {
      const languagePreviouslySelected = this._languageService.getlanguageFromLocalStorage();
      if (languagePreviouslySelected) {
        this.setOldLanguageForm(languagePreviouslySelected);
      } else {
        const userLang = navigator.language;
        if (['en-GB', 'pt-BR', 'es-ES', 'en-AE'].includes(userLang)) {
          this.setOldLanguageForm(userLang);
        } else {
          this.setOldLanguageForm('en-GB');
        }
      }
    }


  }

  setOldLanguageForm(oldLanguage) {
    let language = this.languagesArray.find(language => language.i18n === oldLanguage);
    if (language) {
      this.languageForm.controls.country.setValue(language, { emitEvent: false });
    } else {
      language = this.languagesArray.find(language => language.i18n === this._startupService.getDefaultLanguage());
      this.languageForm.controls.country.setValue(language, { emitEvent: false });
    }
  }

  setLanguage(language) {
    if (language) {
      const storagelanguage = localStorage.getItem('language');
      this._translateService.use(language);
      this._languageService.setChangeLanguage(language)
      if (language !== storagelanguage && this.setOnLocalStorage) {
        localStorage.setItem('language', language);

        if (this._authService.isLoggedIn()) {
          this._languageService.tranlateLegacy(language);
        }
      }
    }
  }
}
