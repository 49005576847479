
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { StartupService } from './startup.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private reload: Subject<boolean> = new Subject<boolean>();
  public changeLanguage: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private changeLanguageFrom = '';

  constructor(
    private _http: HttpClient,
    private _translateService: TranslateService,
    private _authService: AuthService,
    private _startupService: StartupService,
  ) { }

  tranlateLegacy(language: string) {
    this._http.post(`${environment.apiUrl}/language`, {
      language: language
    }).subscribe(() => {
      this.reloadIframe();
    });
  }

  reloadIframe() {
    this.reload.next(true);
  }

  public observeReload(): Observable<boolean> {
    return this.reload.asObservable();
  }

  getPreviousLanguage(): Observable<any> {
    return this._http.get(`${environment.apiUrl}/language`);
  }


  setInitialLanguage() {

    if (this._startupService.getDefaultLanguage()) {

      this._translateService.setDefaultLang('pt-BR');

      const localStorageLanguage = this.getlanguageFromLocalStorage();
      if (localStorageLanguage) {
        this._translateService.use(localStorageLanguage);
        this.changeLanguage.next(localStorageLanguage);

      } else {
        this._translateService.use(this._startupService.getDefaultLanguage());
        this.changeLanguage.next(this._startupService.getDefaultLanguage());
      }

      if (this._authService.isLoggedIn()) {
        this.getPreviousLanguage()
          .pipe(
            tap(previousLanguage => {
              this.saveAndTranslateLegacy(previousLanguage.language);
            }),
            catchError(err => {
              this.setLanguageFromStorage();
              return of()
            })
          )
          .subscribe();
      } else {
        this.setLanguageFromStorage();
      }

    } else {


      const localStorageLanguage = this.getlanguageFromLocalStorage();
      if (localStorageLanguage) {
        this._translateService.use(localStorageLanguage);
        this.changeLanguage.next(localStorageLanguage);

      } else {
        const userLang = navigator.language;
        if (['en-GB', 'pt-BR', 'es-ES', 'en-AE'].includes(userLang)) {
          this._translateService.use(userLang);
          this.changeLanguage.next(userLang);
        } else {
          this._translateService.use('pt-BR');
          this.changeLanguage.next('pt-BR');
        }
      }

    }


  }

  setLanguageFromStorage() {
    const localStorageLanguage = this.getlanguageFromLocalStorage();
    this.changeLanguage.next(localStorageLanguage);

    if (localStorageLanguage) {
      this.saveAndTranslateLegacy(localStorageLanguage);
    } else {
      this.saveAndTranslateLegacy(this._startupService.getDefaultLanguage());
    }

  }

  saveAndTranslateLegacy(language) {
    this._translateService.use(language);
    localStorage.setItem('language', language);
    this.changeLanguage.next(language);


    if (this._authService.isLoggedIn() && this._startupService.isPortalWithIntegration() && this._startupService.hasCSMIntegration()) {
      this.tranlateLegacy(language.label);
    }
  }

  getlanguageFromLocalStorage() {
    return localStorage.getItem('language');
  }

  setChangeLanguage(language) {
    if (language !== this.changeLanguageFrom) {
      this.changeLanguageFrom = language;
      this.changeLanguage.next(language);
    }
  }

  public observeChangeLanguage(): Observable<string> {
    return this.changeLanguage.asObservable();
  }
}
