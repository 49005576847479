<form [formGroup]="filterFormGroup" *ngIf="filterFormGroup">

  <div class="row">
    <cnet-form-field class="col-md-12 col-sm-12" *ngIf="filterFormGroup.controls.InstitutionCodes.enabled">
      <cnet-field-label>{{'Instituição' | translate}}</cnet-field-label>

      <cnet-select name="InstitutionCodes" formControlName="InstitutionCodes" id="InstitutionCodes" show-filter
        [filterPlaceHolder]="'Busque por uma instituição' | translate"
        [placeholder]="'Selecione uma ou mais instituições' | translate"
        [noResultsFoundLabel]="'Nenhum resultado encontrado'" multiple show-tags show-not-found
        [noResultsFoundLabel]="'Nenhum resultado encontrado' | translate"
        [selectAllLabel]="'Selecionar todos' | translate" show-select-all>
        <cnet-select-option *ngFor="let institution of institutions$ | async" [value]="institution?.code"
          [viewValue]="institution?.name"></cnet-select-option>
      </cnet-select>

    </cnet-form-field>
    <cnet-form-field class="col-md-12 col-sm-12" *ngIf="filterFormGroup.controls.CampusCodes.enabled">
      <cnet-field-label>{{'Campus' | translate}}</cnet-field-label>

      <cnet-select name="CampusCodes" formControlName="CampusCodes" id="CampusCodes" show-filter
        [filterPlaceHolder]="'Busque por um campus' | translate"
        [placeholder]="'Selecione um ou mais campus' | translate" multiple show-tags show-not-found
        [noResultsFoundLabel]="'Nenhum resultado encontrado' | translate"
        [selectAllLabel]="'Selecionar todos' | translate" show-select-all>

        <cnet-select-option *ngFor="let campus of campusFiltered" [value]="campus?.code" [viewValue]="campus?.name">
        </cnet-select-option>

      </cnet-select>

    </cnet-form-field>
    <cnet-form-field class="col-md-6 col-sm-12"
      *ngIf="filterFormGroup.value.CampusCodes && filterFormGroup.value.CampusCodes.length && filterFormGroup.controls.CourseCodes.enabled ">
      <cnet-field-label>{{'Curso' | translate}}</cnet-field-label>
      <cnet-select name="CourseCodes" formControlName="CourseCodes" id="CourseCodes" show-filter
        [filterPlaceHolder]="'Busque por um curso' | translate"
        [placeholder]="'Selecione um ou mais cursos' | translate" [noResultsFoundLabel]="'Nenhum resultado encontrado'"
        multiple show-tags [selectAllLabel]="'Selecionar todos' | translate" show-select-all show-not-found
        [noResultsFoundLabel]="'Nenhum resultado encontrado' | translate">
        <cnet-select-option *ngFor="let course of courses$ | async" [value]="course?.code" [viewValue]="course?.name">
        </cnet-select-option>
      </cnet-select>
    </cnet-form-field>

    <cnet-form-field class="col-md-6 col-sm-12"
      *ngIf="!(filterFormGroup.value.CampusCodes && filterFormGroup.value.CampusCodes.length) && filterFormGroup.controls.CourseName.enabled">
      <cnet-field-label>{{'Curso' | translate}}</cnet-field-label>


      <input cnet-input formControlName="CourseName" id="CourseName" name="CourseName" type="text"
        [placeholder]="'Digite o nome de um curso' | translate" [cnetAutocomplete]="autocomplete" autocomplete="off" />
      <cnet-autocomplete #autocomplete="cnetAutocomplete">
        <ng-template cnetAutocompleteContent>



          <ng-container *ngIf="coursesNames$ | async as result; else loadingAutocomplete">
            <ng-container *ngIf="result.length; else emptyAutocomplete">
              <cnet-autocomplete-option *ngFor="let name of result" [value]="name">
                {{ name }}
              </cnet-autocomplete-option>
            </ng-container>
          </ng-container>
          <ng-template #loadingAutocomplete>
            <cnet-autocomplete-option class="no-result"><cnet-progress-dots size="3" tabindex="0"></cnet-progress-dots></cnet-autocomplete-option>
          </ng-template>
          <ng-template #emptyAutocomplete>
            <cnet-autocomplete-option class="no-result" *ngIf="filterFormGroup.controls.CourseName.value && filterFormGroup.controls.CourseName.value.length > 2">
              {{'Nenhum resultado encontrado' | translate}}
            </cnet-autocomplete-option>
          </ng-template>
        </ng-template>
      </cnet-autocomplete>

    </cnet-form-field>

    <cnet-form-field class="col-md-6 col-sm-12" *ngIf="filterFormGroup.controls.CampusState.enabled">
      <cnet-field-label>{{'Estado do Campus' | translate }} </cnet-field-label>

      <cnet-select name="CampusState" formControlName="CampusState" id="CampusState" show-filter
        [filterPlaceHolder]="'Busque pelo estado do campus' | translate"
        [placeholder]="'Selecione uma ou mais estados' | translate" multiple show-tags
        [selectAllLabel]="'Selecionar todos' | translate" show-select-all [viewValueTpl]="labelTpl" show-not-found
        [noResultsFoundLabel]="'Nenhum resultado encontrado' | translate">

        <ng-template #labelTpl let-label>
          <div>
            {{label}}
          </div>
        </ng-template>

        <cnet-select-option *ngFor="let campusState of campusStates$ | async" [value]="campusState?.code"
          viewValue="{{campusState.acronym  }} - {{campusState.name }}" [template]="optionTpl"
          [id]="campusState?.name">

          <ng-template #optionTpl let-option>
            <div class="element-template">
              {{campusState.acronym  }} - {{campusState.name  }}
            </div>
          </ng-template>

        </cnet-select-option>
      </cnet-select>

    </cnet-form-field>

    <cnet-form-field class="col-md-6 col-sm-12" *ngIf="filterFormGroup.controls.Modality.enabled">
      <cnet-field-label>{{'Modalidade' | translate }} </cnet-field-label>
      <cnet-select name="Modality" formControlName="Modality" id="Modality" show-filter
        [filterPlaceHolder]="'Busque por uma modalidade' | translate"
        [placeholder]="'Selecione uma ou mais modalidades' | translate"
        [noResultsFoundLabel]="'Nenhum resultado encontrado'" multiple show-tags
        [selectAllLabel]="'Selecionar todos' | translate" show-select-all show-not-found
        [noResultsFoundLabel]="'Nenhum resultado encontrado' | translate">
        <cnet-select-option *ngFor="let modality of modalities$ | async" [value]="modality?.code"
          [viewValue]="modality?.name | translate">
        </cnet-select-option>
      </cnet-select>
    </cnet-form-field>


    <cnet-form-field class="col-md-6 col-sm-12" *ngIf="filterFormGroup.controls.AdvisorInstitutionName.enabled">
      <cnet-field-label>{{'Nome do orientador do Estágio (Instituição)' | translate}}</cnet-field-label>
      <input cnet-input formControlName="AdvisorInstitutionName" id="AdvisorInstitutionName"
        name="AdvisorInstitutionName" type="text"
        [placeholder]="'Digite o nome do orientador do Estágio (Instituição)' | translate" />
    </cnet-form-field>
  </div>
</form>
