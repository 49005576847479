<ng-template #infoCardListTpl let-list>
  <app-info-card-list [list]="list">
    <app-info-card *ngFor="let internship of list" [id]="internship.id"
      [status]="internship.status | statusTypeTranslate " [color]="internship.status | statusToBadges"
      [showDescription]="true" style=" margin-bottom: 0; width: auto;" [cardInfo]="internship"
      (clickEvent)="navigateToConfig(internship.id)" [titleTemplate]="title" [description]="internship.description">
      <ng-template #title let-card>
        <div>
          {{card.title}}
          <span class="version" cnet-badge cnet-badge-type="stroked" [cnet-badge-color]="'success'">v{{card.data.version
            | number:
            '1.1-1':'en'}}</span>
        </div>
      </ng-template>


      <div class="d-flex m-2" style="margin: 0.5rem 0 !important;">
        <div class="d-flex m-2" class="footer-card">
          <cnet-icon class="mr-1" name="commenting"></cnet-icon>
          <p> {{internship.data.numberOfQuestions}} {{'Perguntas'| translate}} </p>
        </div>
      </div>

    </app-info-card>
  </app-info-card-list>
</ng-template>



<app-page-content [title]="'Estágio Obrigatório' | translate">
  <ng-container *ngIf="mandatoryInternships$  | async as mandatoryInternships; else loadingTemplate">
    <ng-container *ngIf="mandatoryInternships.length else emptyTemplate">

      <ng-template *ngTemplateOutlet="infoCardListTpl; context: {$implicit: mandatoryInternships}"></ng-template>

    </ng-container>
  </ng-container>
</app-page-content>

<app-page-content [title]="'Estágio Não Obrigatório' | translate">
  <ng-container *ngIf="notMandatoryInternships$ |async as notMandatoryInternships; else loadingTemplate">
    <ng-container *ngIf="notMandatoryInternships.length else emptyTemplate">
      <ng-template *ngTemplateOutlet="infoCardListTpl; context: {$implicit: notMandatoryInternships}"></ng-template>
    </ng-container>
  </ng-container>

</app-page-content>


<ng-template #emptyTemplate>
  <app-results-not-found></app-results-not-found>
</ng-template>

<ng-template #loadingTemplate>

  <app-info-card-list [loading]="true"></app-info-card-list>

</ng-template>
