export enum enumReturnUrl {
    CandidatePanel = "/l/candidate/panel",
    CandidateInternships = "/l/candidate/internships",
    CandidateInternshipsCustom = "/l/candidate/internships-custom",
    CompanyPanel = "/l/company/panel",
    CompanyInternships= "/l/company/internships",
    InstitutionAdminPanel = "/l/institution/admin/panel",
    InstitutionAdminInternships = "/l/institution/admin/internships",
    InstitutionCampusAdminInternships = "/l/institution/campusadmin/internships",
    InstitutionAdvisorPanel = "/l/institution/advisor/panel",
    ChangePassword= "/change-password",
    admin = "/l/admin",
    InstitutionAdminConfigurations = "/l/institution/admin/configurations",
    PortalConfigurations = "/settings",
}
