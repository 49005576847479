import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Pipe utilizado para retonar uma url higienizada pelo angular. esta higienização serve para proteger
 * de Cross-site Scripting (XSS)
 */
@Pipe({
  name: 'sanitizerUrl'
})
export class SanitizerUrlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) { }
  transform(url: string, type: string) {

    if (url) {

      switch (type) {
        case 'html':
          return this._sanitizer.bypassSecurityTrustHtml(url);
        case 'style':
          return this._sanitizer.bypassSecurityTrustStyle(url);
        case 'script':
          return this._sanitizer.bypassSecurityTrustScript(url);
        case 'url':
          return this._sanitizer.bypassSecurityTrustResourceUrl(url);
        case 'resourceUrl':
          return this._sanitizer.bypassSecurityTrustResourceUrl(url);
        default:
          return this._sanitizer.bypassSecurityTrustHtml(url);
      }
    } else {
      return this._sanitizer.bypassSecurityTrustResourceUrl('');
    }

  }
}
