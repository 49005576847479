import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appBackgroundImage]',
  standalone:true
})
export class BackgroundImageDirective implements OnInit {

  @Input() appBackgroundImage: string

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
    if(this.appBackgroundImage){
      const child = document.createElement('div');
      child.style.background = 'url("'+this.appBackgroundImage+'") no-repeat';
      child.style.backgroundSize = '90%';
      child.style.position = 'absolute';
      child.style.width = '50%';
      child.style.height = '100%';
      child.style.marginLeft = '40%';
      child.style.marginTop = '-8px';
      child.style.opacity = '0.1';
      this.renderer.appendChild(this.elementRef.nativeElement, child);
    }

  }

}
