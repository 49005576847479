import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { enumPortalType } from '../models/portal.model';
import { AuthService } from '../services/auth.service';
import { StartupService } from '../services/startup.service';

@Injectable({
  providedIn: 'root'
})
export class BlockNotLoggedAndTypeNotInstitution  {

  constructor(
    private _startupService: StartupService,
    private _authService: AuthService,
    private _router: Router
  ) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const type = this._startupService.getPortalType();

    if (type === enumPortalType.Instituicao || this._authService.isLoggedIn()) {
      return true;
    }

    const queryParams = {
      returnUrl: `vaga/visualizar.aspx?v=${route.url[route.url.length - 1].path}`
    };

    this._router.navigate(['auth/students'], { queryParams });
    return false;
  }

}
