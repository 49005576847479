
<ng-container *ngIf="!loading; else loadingTemplate">
  <div class="grid" [class.grid-template-column-not-expansive]="list.length<3">
    <ng-content></ng-content>
  </div>
</ng-container>

<ng-template #loadingTemplate>
  <div class="grid" >
    <app-info-card style=" margin-bottom: 0; width: auto;" [loading]="true"></app-info-card>
    <app-info-card style=" margin-bottom: 0; width: auto;" [loading]="true"></app-info-card>
    <app-info-card style=" margin-bottom: 0; width: auto;" [loading]="true"></app-info-card>
  </div>
</ng-template>


