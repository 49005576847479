
export class CardModel<T> {

  constructor(id?: string, title?: string, description?: string, status?: string, data?: T) {
    if (id) {
      this.id = id;
    }
    if (title) {
      this.title = title;
    }
    if (description) {
      this.description = description;
    }
    this.status = status;
    if (data) {
      this.data = data;
    }
  }
  id: string;
  title: string;
  description: string;
  status?: string;
  data?: T;
}
