import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CnetEvaluationDto } from 'src/app/core/models/cnet-evaluation';
import { EvaluationService } from '../evaluation.service';

@Injectable({
  providedIn: 'root'
})
export class EvaluationByEvaluationCodeResolver  {
  constructor(private evaluationService: EvaluationService, private router: Router) { }
  resolve(route: ActivatedRouteSnapshot): Observable<CnetEvaluationDto> {
    return this.evaluationService.getEvaluationByEvaluationCode(route.params?.evaluationCode).pipe(
      catchError(error => {
        this.router.navigate(['/not-found'])
        return [];
      }),
    );
  }
}
