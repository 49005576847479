<app-error-details [descriptionTemplate]="descTpl"
  [title]="'Oops! Não foi possível concluir sua solicitação neste momento.' | translate">
</app-error-details>
<ng-template #descTpl>
  <div style="min-width: 96px;">
    <h4 class="mb-3">
      {{'Não foi possível recuperar as informações necessárias.' | translate}}<br />
      {{'O(s) seguinte(s) erros foi(foram) encontrado(s):' | translate}}
    </h4>
    <ul *ngFor="let error of errors" cnet-alert-message class="error-label" style="font-size: 20px;">
      <li>
        {{ error | translate }}
      </li>
    </ul>
    <p>
      {{'Tente realizar a mesma operação após corrigir os seus dados.' | translate}}
      {{'Caso o problema persista, entre em contato com a Instituição de Ensino.' | translate}}
    </p>
  </div>
</ng-template>
