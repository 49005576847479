import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { StartupConfig } from 'src/app/core/models/startup-config.model';
import { LoaderService } from 'src/app/core/services/shared/loader.service';
import { StartupService } from 'src/app/core/services/startup.service';
import { IframeRenderedService } from '../../pages/iframe-error/iframe-rendered.service';
import { UrlAuthRedirectService } from '../../pages/url-auth-redirect/url-auth-redirect.service';

@Component({
  selector: 'app-error-details',
  templateUrl: './error-details.component.html',
  styleUrls: ['./error-details.component.scss']
})
export class ErrorDetailsComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() code: string;
  @Input() date: Date = null;
  @Input() descriptionTemplate: TemplateRef<any>;
  @Input() url: string = '';
  @Input() buttonLabel: string = 'Ir para a página inicial';

  config: StartupConfig;


  constructor(
    private _startupService: StartupService,
    private _iframeRenderedService: IframeRenderedService,
    private _urlAuthRedirectService: UrlAuthRedirectService,
    private _loaderService: LoaderService
  ) { }

  ngOnInit() {
    this._urlAuthRedirectService.cleanPreviousSrcUrl();
    this._startupService.getPortalConfig().subscribe((config: StartupConfig) => {
      this.config = config;
    });
    this._loaderService.forceHide();
  }

  navigateHome() {
    if (this._iframeRenderedService.isAppRenderedinsideIframe()) {
      window.parent.window.postMessage({ 'value': 'navigateHome' }, window.location.origin);
    }
  }
}
