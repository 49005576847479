import { Inject, Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { StartupService } from 'src/app/core/services/startup.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyUpdateCodeTokenRedirectGuard  {

  authService = inject(AuthService);
  router = inject(Router);
  startupService = inject(StartupService);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.authService.getCompanyCode() && this.startupService.allowSerproIntegration()) {
      return this.router.navigate([`/companies/${this.authService.getCompanyCode()}/update`])
    }
    else {
      return this.router.navigate(['/not-found'])
    }

  }

}
