import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CnetDatepickerModule } from '@cnet/ngx-ui';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared.module';
import { ErrorDetailsComponent } from './error-details.component';



@NgModule({
  declarations: [ErrorDetailsComponent],
  exports: [ErrorDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule
  ],
  providers: [
  ]
})
export class ErrorDetailsModule { }
