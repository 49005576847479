<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" media="screen"
  rel="stylesheet" />
<div class="content-container shadow">
  <div class="context secondary-text-color mb-4 d-flex justify-content-end">
    <app-language-switcher [setOnLocalStorage]="false" ></app-language-switcher>
  </div>
  <div class="error-circle">
    <i class="fa fa-exclamation fa-lg"></i>
  </div>
  <div class="subheader primary-text-color">
    {{'O sistema encontra-se em manutenção.' | translate}}
  </div>
  <div class="hr"></div>
  <div class="context secondary-text-color">
    <p>{{'Desculpe-nos o inconveniente.' | translate}}</p>
    <p>{{'Por favor, tente novamente mais tarde.' | translate}}</p>
  </div>
</div>
