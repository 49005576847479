import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';

@Component({
  template: `
    <app-error-details
      title="{{'Ops! Algo deu errado.' | translate}}"
      description="{{'Um erro inesperado aconteceu. Por favor tente novamente mais tarde ou sinta-se a vontade para nos contactar.' | translate}}"
      [code]="code$ | async"
      [date]="date">
    </app-error-details>
  `,
  styleUrls: ['./internal-server-error.component.scss']
})
export class InternalServerErrorComponent {
  date = new Date();
  activatedRoute = inject(ActivatedRoute);
  code$ = this.activatedRoute.queryParams.pipe(map(params => { return params?.code ? params?.code : '' }))

}
