import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { StartupService } from '../services/startup.service';

@Injectable({
  providedIn: 'root'
})
export class OpenInOtherTabGuard  {
  constructor(private _startupService: StartupService, private _authService: AuthService){
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const legacyUrl = this._startupService.getLegacyUrl();
      const credential = this._authService.getUserCredential();
      window.open(`https://${legacyUrl}/${route.data.legacyRoute}?credential=${credential}`,'_blank');
    return false;
  }
  
}
