import { Injectable } from '@angular/core';
import { UserDetails } from '../models/user-details';

@Injectable({
  providedIn: 'root'
})
export class RouteConditionService {

  constructor() {
  }

  checkConditions(userDetails: UserDetails, conditions: string[] ){
    if(conditions){
      return conditions.every(condition => userDetails[condition] === true);
    }
    return true;
  }
}
