import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutAndNavigatePublicRouteGuard  {

  constructor(
    private _authService: AuthService,
    private _router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this._authService.isLoggedIn()) {
      this._authService.logout();
    }

    if (!(state.url.includes(`l/${route.url[0].path}`))) {
      this._router.navigate([`l/${route.url[0].path}`], { queryParams: route.queryParams });
      return false;
    } else {
      return true;
    }

  }

}
