import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TargetTypeTrasnlatePipe } from './target-type-trasnlate.pipe';



@NgModule({
  declarations: [TargetTypeTrasnlatePipe],
  imports: [
    CommonModule
  ],
  exports: [TargetTypeTrasnlatePipe],
})
export class TargetTypePipeModule { }
