import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DownloadService } from './download.service';
import { catchError, debounceTime, finalize, map } from 'rxjs/operators';
import { LoaderService } from './shared/loader.service';
import { CreateDocumentRequest } from '../models/cnet-create-document-request';
import { DocumentTypeRequest } from '../models/cnet-document-type-request';
import { Route, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateDocumentOnNewStructureService {

  constructor(
    private downloadService: DownloadService,
    private loaderService: LoaderService,
    private router: Router
  ) { }

  public create(contractDocumentCode, type, language): Observable<string> {
    let body: CreateDocumentRequest = {
      documentTypeInput: {
        type: type,
        language: language
      },
      documentCode: contractDocumentCode,
      forceManualSignature: true
    }

    this.loaderService.show();
    if (body) {
      return this.downloadService.downloadReturnBlobPost(`${environment.apiUrl}/document-create/dynamically`, body)
        .pipe(debounceTime(300), finalize(() => this.loaderService.hide()), map(result => {
          return this.downloadService.getBlobAsUrl(result)
        }), catchError((err:HttpErrorResponse) => {
          return this.handleBlobError(err.error).pipe(catchError((error)=>{
            this.router.navigate(['internal-server-error'], {queryParams:{code: error.logEntryId}})
            throw new Error(error.message);
          }));
        }))
    }
    return of("");
  }

  private handleBlobError(blobError: Blob): Observable<any> {
    return new Observable(observer => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const jsonError = JSON.parse(reader.result as string);
          observer.error(jsonError);
        } catch (e) {
          observer.error('An unexpected error occurred');
        }
      };
      reader.onerror = () => {
        observer.error('An unexpected error occurred');
      };
      reader.readAsText(blobError);
    });
  }



  public openOnNewTab(contractDocumentCode, isElegibleToBePrinted, type, language, saveDocument): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/document`], {
        queryParams: {
          contractDocumentCode,
          isElegibleToBePrinted,
          type,
          language,
          saveDocument
        }
      })
    );

    window.open(url, '_blank');
  }
}
