import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { enumStatusTranslated } from 'src/app/core/enums/enumStatus';

@Pipe({
  name: 'statusTypeTranslate',
  pure: false
})
export class StatusTypePipe implements PipeTransform {

  constructor(private translateService: TranslateService) { }

  transform(value: string): string {
    return this.translateService.instant(enumStatusTranslated[`${value}`]);
  }

}
