import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getTranslatedRoleByCode } from 'src/app/core/enums/role';

@Pipe({
  name: 'permissionRoleId',
  pure: false,
  standalone: true
})
export class PermissionRoleIdTranslatePipe implements PipeTransform {

  translateService = inject(TranslateService)
  transform(code: string): any {
    if (code) {
      const role = getTranslatedRoleByCode(code)
      if(role){
        return this.translateService.instant(role);
      }
    }
    return ''

  }

}
