@if(subHeaderType === 'default'){

<div class="container-fluid" style="border-bottom: 0;">
  <div class="d-flex justify-content-between align-items-center" [class.flex-wrap]="isMobile">
    <div class="header d-flex align-items-center ">

      <a cnet-icon-button *ngIf="returnUrl" class="mr-2 mb-1" [routerLink]="[returnUrl]">
        <cnet-icon name="angle-left"></cnet-icon>
      </a>

      <h2 class="title" [ngStyle]="{'margin-left': !returnUrl ? '3rem' : '0' }">{{headerTitle}}</h2>

    </div>
    <div class="content" [class.mb-2]="isMobile" [class.margin-left-3]="isMobile">
      <ng-content></ng-content>
    </div>
  </div>
</div>
}@else {
<div class="container-fluid" style="border-bottom: 0;">
  <div class="d-flex w-100 justify-content-between align-items-center">

    <a cnet-icon-button *ngIf="returnUrl" class="mr-2 mb-1" [routerLink]="[returnUrl]">
      <cnet-icon name="angle-left"></cnet-icon>
    </a>

    <div class="d-flex w-100 justify-content-between align-items-center" [class.flex-wrap]="isMobile">
      <div class="header w-100 d-flex align-items-center ">
        <ng-content select="app-sub-header-content, [app-sub-header-content], [CnetSubHeaderContent]"></ng-content>
      </div>
      <div class="d-flex ml-1 align-self-stretch align-items-start" [class.mt-5]="!isMobile" [class.mt-0]="isMobile"
        [class.pb-5]="isMobile" [class.w-100]="isMobile" [class.justify-content-center]="isMobile">
        <ng-template *ngTemplateOutlet="actionsTpl"></ng-template>
      </div>
    </div>

  </div>
</div>

<ng-template #actionsTpl>
  <ng-content select="permissions-header-actions, [permissions-header-actions] [appSubHeaderActions]"></ng-content>
</ng-template>
}

