<ng-container *ngIf="!loading; else loadingTemplate">


  <div class="status-container" href="#" *ngIf="status" cnet-badge cnet-badge-type="flat" [cnet-badge-color]="color">
    <div class="status-description">
      {{status }}
    </div>
  </div>

  <div class="go-corner" [class.go-corner-description-on-hover]="showDescriptionOnHover" href="#" *ngIf="isLink">
    <div class="go-arrow" *ngIf="!showDescriptionOnHover">
      →
    </div>
  </div>

  <div class="go-arrow-description-on-hover" *ngIf="showDescriptionOnHover && isLink">
    →
  </div>


  <h3 class="card-title break-word" [class.card-title-description-on-hover]="showDescriptionOnHover && isLink">
    <ng-container *ngIf="titleTemplate; else elseTemplate">
      <ng-template *ngTemplateOutlet="titleTemplate; context: {$implicit: cardInfo}"></ng-template>
    </ng-container>
    <ng-template #elseTemplate>
      {{title}}
    </ng-template>
  </h3>

  <ng-container *ngIf="showDescription">
    <ng-container *ngIf="showDescriptionOnHover; else elseTemplate">

      <div [class.description-on-hover]="showDescriptionOnHover">
        <ng-template *ngTemplateOutlet="descriptionTpl"></ng-template>
      </div>

    </ng-container>
    <ng-template #elseTemplate>

      <ng-template *ngTemplateOutlet="descriptionTpl"></ng-template>


    </ng-template>
  </ng-container>


  <ng-template #descriptionTpl>
    <p class="break-word">
      <ng-container *ngIf="descriptionTemplate; else elseTemplate">
        <ng-template *ngTemplateOutlet="descriptionTemplate; context: {$implicit: cardInfo}"></ng-template>
      </ng-container>
      <ng-template #elseTemplate>
        {{description}}
      </ng-template>
    </p>


  </ng-template>

  <div class="dynamic-content">
    <ng-content></ng-content>
  </div>

  <div class="background background-description-on-hover" [appBackgroundImage]="backgroundImage" >
    <div class="tiles">
      <div class="tile tile-1"></div>
      <div class="tile tile-2"></div>
      <div class="tile tile-3"></div>
      <div class="tile tile-4"></div>

      <div class="tile tile-5"></div>
      <div class="tile tile-6"></div>
      <div class="tile tile-7"></div>
      <div class="tile tile-8"></div>

      <div class="tile tile-9"></div>
      <div class="tile tile-10"></div>
    </div>

    <div class="line line-1"></div>
    <div class="line line-2"></div>
    <div class="line line-3"></div>
  </div>

</ng-container>
<ng-template #loadingTemplate>

  <div class="is-loading">
  </div>

  <div class="is-loading content">
  </div>

</ng-template>
