import { Component, inject } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  template: `
  <app-error-details
      title="{{'Oops! Não foi possível encontrar essa empresa.' | translate}}"
      description="{{'Validamos o CNPJ da empresa na Receita Federal e o registro não foi encontrado. Confira o CNPJ e cadastre novamente a empresa.' | translate}}"
      [url]="routerLink"
      [buttonLabel]="buttonLabel"
      >

    </app-error-details>
  `,
  styleUrls: ['./invalid-company.component.scss']
})
export class InvalidCompanyComponent {

  authService = inject(AuthService);


  routerLink = this.authService.isLoggedIn() ? '/l/institution/admin/companies' : '';
  buttonLabel = this.authService.isLoggedIn() ? 'Voltar para listagem de empresas' : 'Ir para a página inicial';


}
