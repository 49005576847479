import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import TinyColor from 'tinycolor2';
import { ColorPalette } from '../models/color-palette.model';
import { PortalColorPalettes } from '../models/portal-color-palettes';
import { PortalColors } from '../models/portal-colors.model';
import { StartupConfig } from '../models/startup-config.model';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  private _palettes: PortalColorPalettes;

  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: Document, private titleService: Title) { }

  setColors(colors: PortalColors) {
    for (const i in colors) {
      if (colors[i]) {
        this.setColor(i, colors[i]);
      }
    }
  }

  setColor(colorName: string, color) {
    this.setPalette(colorName, this.createPaletteFromColor(color));
  }

  setPalette(paletteName: string, palette) {
    for (const i in palette) {
      if (i !== 'contrast') {
        this.setColorVariable(paletteName, i, palette[i]);
      } else {
        const contrastPalette = palette[i];
        for (const j in contrastPalette) {
          if (contrastPalette[j]) {
            this.setColorVariable(paletteName, j, contrastPalette[j], true);
          }
        }
      }
    }
  }

  setColorVariable(paletteName: string, colorName: string, color: string, isContrast: boolean = false) {
    this.document.documentElement.style.setProperty(`--cnet-${paletteName}${isContrast ? '-constrast' : ''}-${colorName}`, color);
  }

  setFavIcon(url: string) {
    const favIcon: HTMLLinkElement = document.querySelector('#ngFavIcon');
    favIcon.href = url;
    // this.document.getElementById('ngFavIcon')?.setAttribute('href', url);
  }

  setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  createPaletteFromColor(color: string): ColorPalette {
    const baseColor = TinyColor(color);

    return {
      50: baseColor.clone().lighten(52).toHexString(),
      100: baseColor.clone().lighten(37).toHexString(),
      200: baseColor.clone().lighten(26).toHexString(),
      300: baseColor.clone().lighten(12).toHexString(),
      400: baseColor.clone().lighten(6).toHexString(),
      500: baseColor.clone().toHexString(),
      600: baseColor.clone().darken(6).toHexString(),
      700: baseColor.clone().darken(12).toHexString(),
      800: baseColor.clone().darken(18).toHexString(),
      900: baseColor.clone().darken(24).toHexString(),
      A100: baseColor.clone().lighten(50).saturate(30).toHexString(),
      A200: baseColor.clone().lighten(30).saturate(30).toHexString(),
      A400: baseColor.clone().lighten(10).saturate(15).toHexString(),
      A700: baseColor.clone().lighten(5).saturate(5).toHexString(),
      contrast: {
        50: baseColor.clone().lighten(52).isLight() ? '#fff' : '#000',
        100: baseColor.clone().lighten(37).isLight() ? '#fff' : '#000',
        200: baseColor.clone().lighten(26).isLight() ? '#fff' : '#000',
        300: baseColor.clone().lighten(12).isLight() ? '#fff' : '#000',
        400: baseColor.clone().lighten(6).isLight() ? '#fff' : '#000',
        500: baseColor.clone().isLight() ? '#fff' : '#000',
        600: baseColor.clone().darken(6).isLight() ? '#fff' : '#000',
        700: baseColor.clone().darken(12).isLight() ? '#fff' : '#000',
        800: baseColor.clone().darken(18).isLight() ? '#fff' : '#000',
        900: baseColor.clone().darken(24).isLight() ? '#fff' : '#000',
        A100: baseColor.clone().lighten(50).saturate(30).isLight() ? '#fff' : '#000',
        A200: baseColor.clone().lighten(30).saturate(30).isLight() ? '#fff' : '#000',
        A400: baseColor.clone().lighten(10).saturate(15).isLight() ? '#fff' : '#000',
        A700: baseColor.clone().lighten(5).saturate(5).isLight() ? '#fff' : '#000',
      }
    };
  }

  setUiByConfig(config: StartupConfig) {
    this.setColors(config.portal.colors);
    this.setFavIcon(config.portal.favIcoUrl);
    this.setTitle(config.portal.name);
  }
}
