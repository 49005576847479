import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-evaluation-home',
  templateUrl: './evaluation-home.component.html',
  styleUrls: ['./evaluation-home.component.scss']
})
export class EvaluationHomeComponent {

  @ViewChild('configEvalTab') configEvalTab = null;
  constructor(
    private permmissionsService: NgxPermissionsService
  ) { }

  hasEvaluationConfigModuleAccessPermission(){
    return this.permmissionsService.getPermission('EvaluationConfigModuleAccess');
  }

}
