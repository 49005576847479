import { Pipe, PipeTransform } from '@angular/core';
import { enumStatusToBadge } from 'src/app/core/enums/enumStatus';

@Pipe({
  name: 'statusToBadges'
})
export class StatusToBadgesPipe implements PipeTransform {

  transform(value: string): string {
    return enumStatusToBadge[`${value}`];
  }

}
