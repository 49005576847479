import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { InfoCardComponent } from '../info-card/info-card.component';

@Component({
  selector: 'app-info-card-list',
  standalone: true,
  imports: [CommonModule, InfoCardComponent],
  templateUrl: './info-card-list.component.html',
  styleUrls: ['./info-card-list.component.scss']
})
export class InfoCardListComponent {

  @Input() list: Array<any> = [];
  @Input() loading = false;
}
