<form [formGroup]="filterFormGroup" *ngIf="filterFormGroup">


  <div class="row">

    <cnet-form-field class="col-md-6 col-sm-12" *ngIf="filterFormGroup.controls.InternshipStatus.enabled">
      <cnet-field-label>{{'Status' | translate}}</cnet-field-label>

      <cnet-select name="InternshipStatus" formControlName="InternshipStatus" id="InternshipStatus" show-filter
        [filterPlaceHolder]="'Busque por um status' | translate" [placeholder]="'Selecione um status' | translate"
        multiple show-tags show-filter>
        <cnet-select-option *ngFor="let status of InternshipStatus$ | async" [value]="status?.code"
          [viewValue]="status?.name"></cnet-select-option>
      </cnet-select>

    </cnet-form-field>

    <cnet-form-field class="col-md-6 col-sm-12" *ngIf="filterFormGroup.controls.InternshipCondition.enabled">
      <cnet-field-label>{{'Condição' | translate}}</cnet-field-label>

      <cnet-select name="InternshipCondition" formControlName="InternshipCondition" id="InternshipCondition" show-filter
        [filterPlaceHolder]="'Busque por uma condição' | translate" [placeholder]="'Selecione uma condição' | translate"
        multiple show-tags>
        <cnet-select-option *ngFor="let status of conditions$ | async" [value]="status?.code"
          [viewValue]="status?.name"></cnet-select-option>
      </cnet-select>

    </cnet-form-field>
  </div>

  <div class="row">
    <cnet-form-field class="col-md-4 col-sm-12" *ngIf="filterFormGroup.controls.InternshipIsMandatory.enabled">
      <cnet-field-label>{{'Tipo de estágio' | translate}}</cnet-field-label>
      <cnet-select formControlName="InternshipIsMandatory" id="InternshipIsMandatory" name="InternshipIsMandatory"
        show-filter [filterPlaceHolder]="'Busque por um tipo de estágio' | translate"
        [placeholder]="'Selecione um tipo de estágio' | translate">
        <cnet-select-option [value]="true" [viewValue]="'Obrigatório'| translate"></cnet-select-option>
        <cnet-select-option [value]="false" [viewValue]="'Não Obrigatório'| translate"></cnet-select-option>
      </cnet-select>
    </cnet-form-field>
  </div>

  <div class="row row-dates"
    *ngIf="filterFormGroup.controls.InternshipDateFrom.enabled && filterFormGroup.controls.InternshipDateTo.enabled">

    <cnet-form-field class="col-md-6 col-sm-12">
      <cnet-field-label>{{'Data do Estágio' | translate}}</cnet-field-label>
      <input [cnetDatepickerInput]="InternshipDateFrom" formControlName="InternshipDateFrom"
        id="InternshipDateFrom" name="InternshipDateFrom" form type="text" />
      <cnet-datepicker #InternshipDateFrom></cnet-datepicker>
    </cnet-form-field>
    <div>{{'até' | translate}}</div>
    <cnet-form-field class="col-md-6 col-sm-12">
      <input [cnetDatepickerInput]="InternshipDateTo" formControlName="InternshipDateTo"
        id="InternshipDateTo" name="InternshipDateTo" form type="text" />
      <cnet-datepicker #InternshipDateTo></cnet-datepicker>
    </cnet-form-field>
  </div>

  <div class="row row-dates"
    *ngIf="filterFormGroup.controls.InternshipCreateDateFrom.enabled && filterFormGroup.controls.InternshipCreateDateTo.enabled">
    <cnet-form-field class="col-md-6 col-sm-12">
      <cnet-field-label>{{'Data de Cadastro' | translate}}</cnet-field-label>
      <input [cnetDatepickerInput]="InternshipCreateDateFrom" formControlName="InternshipCreateDateFrom"
        id="InternshipCreateDateFrom" name="InternshipCreateDateFrom" form type="text" />
      <cnet-datepicker #InternshipCreateDateFrom></cnet-datepicker>
    </cnet-form-field>
    <div>
      {{'até'| translate}}
    </div>
    <cnet-form-field class="col-md-6 col-sm-12">
      <input [cnetDatepickerInput]="InternshipCreateDateTo" formControlName="InternshipCreateDateTo"
        id="InternshipCreateDateTo" name="InternshipCreateDateTo" form type="text" />
      <cnet-datepicker #InternshipCreateDateTo></cnet-datepicker>
    </cnet-form-field>
  </div>

  <div class="row">
    <div class="col-sm-6 margin-bottom-input">
      <div>
        <div *ngIf="filterFormGroup.controls.InternshipWithAmmendment.enabled">
          <cnet-checkbox #InternshipWithAmmendment formControlName="InternshipWithAmmendment"
            id="InternshipWithAmmendment" name="InternshipWithAmmendment">
            {{'Estágios com termo de aditamento (renovados/aditados)' | translate}}</cnet-checkbox>
        </div>
        <div *ngIf="filterFormGroup.controls.InternshipWithIrregularAcademicStatis.enabled">
          <cnet-checkbox #InternshipWithIrregularAcademicStatis formControlName="InternshipWithIrregularAcademicStatis"
            id="InternshipWithIrregularAcademicStatis" name="InternshipWithIrregularAcademicStatis">
            {{'Estagiários com situação acadêmica irregular' |translate}}
          </cnet-checkbox>
        </div>
        <div *ngIf="filterFormGroup.controls.InternshipWithSuspension.enabled">
          <cnet-checkbox #InternshipWithSuspension formControlName="InternshipWithSuspension"
            id="InternshipWithSuspension" name="InternshipWithSuspension">
            {{'Estágios com suspensão'| translate}}
          </cnet-checkbox>
        </div>
        <div *ngIf="filterFormGroup.controls.InternshipWithIntegrationAgency.enabled">
          <cnet-checkbox #InternshipWithIntegrationAgency formControlName="InternshipWithIntegrationAgency"
            id="InternshipWithIntegrationAgency" name="InternshipWithIntegrationAgency">
            {{'Estágios com agência de integração' | translate}}
          </cnet-checkbox>
        </div>
        <div *ngIf="filterFormGroup.controls.InternshipWithEquivalence.enabled">
          <cnet-checkbox #InternshipWithEquivalence formControlName="InternshipWithEquivalence"
            id="InternshipWithEquivalence" name="InternshipWithEquivalence">
            {{'Estágios de equivalência' | translate}}
          </cnet-checkbox>
        </div>
      </div>
    </div>

  </div>

  <div class="row"
    *ngIf="filterFormGroup.controls.InternshipWithFormsPendinValidation.enabled || filterFormGroup.controls.InternshipWithPedingDocuments.enabled">
    <div class="col-sm-6 margin-bottom-input">
      <label class="group-label" id="group-label">
        {{'Deferimento de Estágio' | translate}}
      </label>
      <div>
        <div>
          <cnet-checkbox #InternshipWithFormsPendinValidation formControlName="InternshipWithFormsPendinValidation"
            *ngIf="filterFormGroup.controls.InternshipWithFormsPendinValidation.enabled"
            id="InternshipWithFormsPendinValidation" name="InternshipWithFormsPendinValidation">
            {{'Formulários pendentes devalidação' | translate}}
          </cnet-checkbox>
        </div>
        <div>
          <cnet-checkbox #InternshipWithPedingDocuments formControlName="InternshipWithPedingDocuments"
            *ngIf="filterFormGroup.controls.InternshipWithPedingDocuments.enabled" id="InternshipWithPedingDocuments"
            name="InternshipWithPedingDocuments">
            {{'Documentos pendentes de deferimento' | translate}}
          </cnet-checkbox>
        </div>
      </div>
    </div>
  </div>

</form>
