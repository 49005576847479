
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoaderService } from 'src/app/core/services/shared/loader.service';

@Component({
  selector: 'app-signin-symplicity',
  templateUrl: './signin-symplicity.component.html',
  styleUrls: ['./signin-symplicity.component.scss']
})
export class SigninSymplicityComponent implements OnInit {
  public errors: string[];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _loaderService: LoaderService,
    private _authService: AuthService,
  ) { }

  ngOnInit(): void {
    this._authService.logout();
    this._loaderService.forceHide();
    this.errors = this._activatedRoute.snapshot.data.errorGuid;
  }

}
