import { Component } from '@angular/core';

@Component({
  template: `
  <app-error-details
      title="{{'Oops! Um erro inesperado aconteceu.' | translate}}"
      description="{{'Não conseguimos encontrar a página que você estava buscando. Se você encontrou algum problema, comunique-nos.' | translate}}">
  </app-error-details>
  `,
  styleUrls: ['./iframe-error.component.scss']
})
export class IframeErrorComponent {
}

