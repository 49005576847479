import { UntypedFormGroup } from '@angular/forms';

export abstract class AbstractFilter<T> {
  abstract createGroup(): UntypedFormGroup;

  filterFormGroup: UntypedFormGroup;

  disable(control: string) {
    if (this.filterFormGroup) {
      this.filterFormGroup.get(control).disable();
    } else {
      throw new Error('Filter form group is not defined');
    }
  }

  disableControls(controls: Array<T>) {
    controls.forEach(c => this.disable(c.toString()))
  }

  getFilledFilters() {
    return Object.keys(this.filterFormGroup.controls).filter(control => Array.isArray(this.filterFormGroup.value[control]) ? this.filterFormGroup.value[control].length : this.filterFormGroup.value[control]);
  }
  getAmountFiltersWithValue() {
    const controlsWithValue = this.getFilledFilters();
    return controlsWithValue.length;
  }


}
