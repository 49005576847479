import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ADMIN_LEGACY_ROUTES } from './legacy-routes/admin-routes';
import { CAMPUS_ADMIN_LEGACY_ROUTES } from './legacy-routes/campus-admin-routes';
import { CANDIDATE_LEGACY_ROUTES } from './legacy-routes/candidate-routes';
import { COMPANY_LEGACY_ROUTES } from './legacy-routes/company-routes';
import { INSTITUTION_ADMIN_LEGACY_ROUTES } from './legacy-routes/institution-admin-routes';
import { INSTITUTION_ADVISOR_LEGACY_ROUTES } from './legacy-routes/institution-advisor-routes';
import { MANAGER_LEGACY_ROUTES } from './legacy-routes/manager-routes';
import { PUBLIC_LEGACY_ROUTES } from './legacy-routes/public-routes';

export const LEGACY_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/'
  },
  { path: 'candidate', children: CANDIDATE_LEGACY_ROUTES },
  { path: 'company', children: COMPANY_LEGACY_ROUTES },
  { path: 'institution/admin', children: INSTITUTION_ADMIN_LEGACY_ROUTES },
  { path: 'institution/campusadmin', children: CAMPUS_ADMIN_LEGACY_ROUTES },
  { path: 'institution/advisor', children: INSTITUTION_ADVISOR_LEGACY_ROUTES },
  { path: 'manager', children: MANAGER_LEGACY_ROUTES },
  { path: 'admin', children: ADMIN_LEGACY_ROUTES },
];
@NgModule({
  imports: [RouterModule.forChild(LEGACY_ROUTES.concat(PUBLIC_LEGACY_ROUTES))],
  exports: [RouterModule]
})
export class LegacyRoutingModule { }
