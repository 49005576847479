import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ReturnUrlDetailsResolver  {
  resolve(route: ActivatedRouteSnapshot): string {
    return `/evaluation/${route.params.evaluationCode}/details`;
  }
}
