
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { StartupService } from 'src/app/core/services/startup.service';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

  links = [];
  hasLinks = true;

  constructor(
    private _startupService: StartupService
  ) { }

  ngOnInit(): void {

    const social = this._startupService.getSocialMedia();
    if (social) {

      this.hasLinks = Object.values(social).find(value => value !== null);
      this.links = Object.keys(social).map(key => { return { href: social[key] ? `https://www.${key}.com/${key === 'linkedIn' ? 'company/' : ''}${social[key]}` : social[key], icon: key.toLocaleLowerCase(), title: key } });
    }

  }

}
