import { SelectOptionModel } from '../models/select-option.model';

export type InternshipType = 'All' | 'Mandatory' | 'NotMandatory';

export enum enumInternshipType {
  'All' = 'All',
  'Mandatory' = 'Mandatory',
  'NotMandatory' = 'NotMandatory',
  'Not Mandatory' = 'NotMandatory',
}
export enum enumInternshipTypeTranslate {
  'Todos' = 'All',
  'Obrigatório' = 'Mandatory',
  'Não Obrigatório' = 'NotMandatory',
}
export enum enumInternshipTypeTranslated {
  'All' = 'Todos',
  'Mandatory' = 'Obrigatório',
  'NotMandatory' = 'Não Obrigatório',
  'Not Mandatory' = 'Não Obrigatório',
}

export function enumInternshipTypeAsArray() {
  const internshipTypes = enumInternshipTypeTranslate;
  return Object.keys(internshipTypes).map(type => { return new SelectOptionModel<InternshipType>(type, enumInternshipTypeTranslate[type]) })
}
