<div class="brand" *ngIf="config">
  <img class="portal-logo" [src]="config.portal.lightLogoUrl" [alt]="config.portal.name">
</div>

<cnet-divider></cnet-divider>

<h1 class="title">{{title}}</h1>

<ng-template [ngIf]="!descriptionTemplate">
  <p>{{description}}</p>
</ng-template>
<ng-template *ngTemplateOutlet="descriptionTemplate"></ng-template>

<div class="details">
  <div *ngIf="code">
    <b>{{'Código do erro:' | translate}}</b> {{code}}
  </div>

  <div *ngIf="date">
    <b>{{'Data e horário:' | translate}}</b> {{date | i18nDate:'medium'}}
  </div>
</div>

<a cnet-filled-button color="primary" type="button" (click)="navigateHome()" [routerLink]="[url]">
  {{buttonLabel |translate}}</a>
<!-- TODO: Possibilitar o reporte de problemas -->
<!-- <button cnet-button class="return-to-home" color="primary">Reportar problema</button> -->
