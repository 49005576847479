import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageContetSideNavComponent } from './page-contet-side-nav.component';
import { CnetNgxUiModule } from '@cnet/ngx-ui';



@NgModule({
  declarations: [
    PageContetSideNavComponent,
  ],
  imports: [
    CommonModule,
    CnetNgxUiModule
  ],
  exports: [
    PageContetSideNavComponent
  ],
})
export class PageContetSideNavModule { }
