import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/services/language.service';
import { StartupService } from 'src/app/core/services/startup.service';
import { UiService } from 'src/app/core/services/ui.service';
import { CUSTOM_COLORS } from './custom-colors';
import { PROFILES } from './profiles';

@Component({
  selector: 'app-config-tester',
  templateUrl: './config-tester.component.html',
  styleUrls: ['./config-tester.component.scss']
})
export class ConfigTesterComponent implements OnInit {
  colors = CUSTOM_COLORS;
  profiles = PROFILES;

  constructor(
    private _uiService: UiService,
    private _translateService: TranslateService,
    private _startupService: StartupService,
    private _cdr: ChangeDetectorRef,
    private _languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this._cdr.detectChanges();
  }

  setColor(name, color) {
    this._uiService.setColor(name, color);
  }

  setLanguage(language) {
    this._translateService.use(language);
    // this._languageService.tranlateLegacy(language);
    this._cdr.markForCheck();
  }

  setLogo(logoUrl) {
    this._startupService.setLogo(logoUrl);
  }

  setProfile(profile) {
    this.setColor('primary', profile.primaryColor);
    this.setColor('secondary', profile.secondaryColor);
    this.setLanguage(profile.language);
    this.setLogo(profile.logoUrl);
  }
}
