import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BasicModel } from '../models/basic-model';
import { CampusFilterResponse } from '../models/cnet-campus-filter-response';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService {

  constructor(private http: HttpClient) { }

  getAllInstitutions() {
    return this.http.get<Array<BasicModel>>(`${environment.apiUrl}/institution`);
  }
  getCampusByInstitutionCode(institutionCodeList?: Array<number>) {
    // talvez seja melhor musdar para um paramentro
    let params = new HttpParams();
    if (institutionCodeList && institutionCodeList.length) {
      institutionCodeList.forEach(code => {
        params = params.append('institutionCode', code.toString())
      })
    }
    return this.http.get<Array<CampusFilterResponse>>(`${environment.apiUrl}/campus`, { params });
  }

  getCourseByCampusCode(campusCodeList: Array<number>) {
    return this.http.post<Array<BasicModel>>(`${environment.apiUrl}/courses`, {campusCodes: campusCodeList});
  }

  getModalities(): Observable<Array<BasicModel>> {
    return this.http.get<Array<BasicModel>>(`${environment.apiUrl}/courses/modalities`);
  }
  getLevels(): Observable<Array<BasicModel>> {
    return this.http.get<Array<BasicModel>>(`${environment.apiUrl}/courses/levels`);
  }

  getCampusStateByCampusCode(campusCodes: Array<number>): Observable<BasicModel[]> {
    let params = new HttpParams();

    campusCodes.forEach(code => {
      params = params.append('campusCode', code.toString())
    });

    return this.http.get<Array<BasicModel>>(`${environment.apiUrl}/location/states/by-campus`, { params });
  }

  getCoursesNames(hint: string): Observable<Array<string>> {
    return this.http.get<Array<string>>(`${environment.apiUrl}/courses/auto-complete/${hint}`);
  }

}
