import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EvaluationService } from '../evaluation.service';

@Injectable({
  providedIn: 'root'
})
export class EvaluationActionsGuard  {

  constructor(private evaluation: EvaluationService) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.evaluation.getActionsByEvaluationCode(route.params.evaluationCode).pipe(map(actions => {
      return actions[route.data.action];
    }))
  }

}
