import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationModel } from '@cnet/ngx-ui';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private _httpClient: HttpClient) { }

  getNotifications(userRequest? : boolean) {
    if(userRequest === true)
      return this._httpClient.get<Array<NotificationModel>>(`${environment.apiUrl}/notification/user-request`);
    else
      return this._httpClient.get<Array<NotificationModel>>(`${environment.apiUrl}/notification`);
  }


}
