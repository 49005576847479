import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CnetPagedList } from '../models/cnet-paged-list';

export const requestUrlPhoto = '/user/url-photo'

@Injectable({
  providedIn: 'root'
})
export class UsersProfilePicsService {

  http = inject(HttpClient)

  getUsersProfilePicsService(users: Array<string>): Observable<Array<{ userCode: string, urlPhoto: string }>> {
    if (users?.length) {
      return this.http.post<Array<{ userCode: string, urlPhoto: string }>>(`${environment.apiUrl}${requestUrlPhoto}`,
        { encriptedUsersCode: users })
    } else {
      return of([])
    }
  }


  getUsersProfilePicsUsingPagedList<T>(users: CnetPagedList<T>, userCodeAttribute: string = 'code') {
    if (users?.subset?.length) {
      return this.getUsersProfilePicsService(users.subset.map(user => user[userCodeAttribute]))
        .pipe(
          map(photos => {
            users.subset.forEach(user => {
              user['urlPhoto'] = photos.find(photo => photo.userCode === user[userCodeAttribute]).urlPhoto;
            })
            return users
          }),
          catchError(err => of(users))
        )
    }
    else {
      return of(users)
    }
  }
}
