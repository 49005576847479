export type InternshipFilterType =
  "InternshipStatus" |
  "InternshipCondition" |
  "InternshipIsMandatory" |

  "InternshipDateFrom" |
  "InternshipDateTo" |
  "InternshipCreateDateFrom" |
  "InternshipCreateDateTo" |

  "InternshipWithAmmendment" |
  "InternshipWithIrregularAcademicStatis" |
  "InternshipWithSuspension" |
  "InternshipWithIntegrationAgency" |
  "InternshipWithEquivalence" |
  "InternshipWithFormsPendinValidation" |
  "InternshipWithPedingDocuments"



export enum enumInternshipFilter {
  "InternshipStatus" = "InternshipStatus",
  "InternshipCondition" = "InternshipCondition",
  "InternshipIsMandatory" = "InternshipIsMandatory",
  "InternshipDateFrom" = "InternshipDateFrom",
  "InternshipDateTo" = "InternshipDateTo",
  "InternshipCreateDateFrom" = "InternshipCreateDateFrom",
  "InternshipCreateDateTo" = "InternshipCreateDateTo",
  "InternshipWithAmmendment" = "InternshipWithAmmendment",
  "InternshipWithIrregularAcademicStatis" = "InternshipWithIrregularAcademicStatis",
  "InternshipWithSuspension" = "InternshipWithSuspension",
  "InternshipWithIntegrationAgency" = "InternshipWithIntegrationAgency",
  "InternshipWithEquivalence" = "InternshipWithEquivalence",
  "InternshipWithFormsPendinValidation" = "InternshipWithFormsPendinValidation",
  "InternshipWithPedingDocuments" = "InternshipWithPedingDocuments"
}
