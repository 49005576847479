import { HttpParams } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { StartupService } from 'src/app/core/services/startup.service';
import { UrlAuthRedirectService } from 'src/app/shared/pages/url-auth-redirect/url-auth-redirect.service';

@Component({
  templateUrl: './legacy-render.component.html',
  styleUrls: ['./legacy-render.component.scss']
})
export class LegacyRenderComponent implements OnInit, OnDestroy {
  path: string;
  credential = '';
  legacyUrl: string;

  navigationSubscription;

  allowRedirect: boolean;

  hadRefresh = false;

  constructor(
    private _authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private _startupService: StartupService,
    private urlAuthRedirectService: UrlAuthRedirectService,
  ) {
    this.credential = this._authService.getUserCredential();
    this.legacyUrl = this._startupService.getLegacyUrl();

    this.navigationSubscription = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: any) => {
      if (e instanceof NavigationEnd) {

        if (e.id === 1 && e.url === e.urlAfterRedirects) {
          // had a refresh
          this.hadRefresh = true;
        }
        this.route.data.subscribe(data => {
          this.updatePath('');
          const isJobRoute = this.route.snapshot.url.find(a => a.path === 'job');
          if (isJobRoute) {
            const params = this.route.snapshot.url.map(a => a.path).splice(1).reduce((a, b) => a + '/' + b);
            this.resolvePath(params);
          } else {
            this.resolvePath(data.legacyRoute);
          }
        });
      }
    });

  }

  ngOnInit() {

  }

  resolvePath(legacyRoute) {
    let src = '';
    let parametros = '';
    let pathSavedBeforeRefresh = sessionStorage.getItem('path');
    const angularRouteBeforeRefresh = sessionStorage.getItem('angular-route');

    //if is a route with params like document then I'll add those parameters in iframe url
    Object.keys(this.route.snapshot.params).filter((param) =>
      param !== 'token')
      .forEach((key) => {
        parametros += `${this.route.snapshot.params[key]}/`;
      });

    // in case of a page refresh, I use the saved path on session storage.
    if (this.hadRefresh && !!pathSavedBeforeRefresh && angularRouteBeforeRefresh === document.location.href) {
      // setTimeout(() => {
      pathSavedBeforeRefresh = this.cleanPath(pathSavedBeforeRefresh);

      this.updatePath(pathSavedBeforeRefresh);
      sessionStorage.removeItem('path');
      sessionStorage.removeItem('angular-route');
      this.hadRefresh = false;
      // }, 100);

    } else {

      if (this.urlAuthRedirectService.previousSrcUrl) {
        src = this.urlAuthRedirectService.previousSrcUrl;
        this.urlAuthRedirectService.cleanPreviousSrcUrl();
        this.updatePath(`${this.legacyUrl}${src}`);
      } else if (this.route.snapshot.queryParams) {

        if (this.route.snapshot.queryParams.src) {
          src = this.route.snapshot.queryParams.src;
          this.updatePath(
            `${src.includes('http') ? '' : `${this.legacyUrl}`}${src.includes('https') ? src : src.replace(/http/, 'https')}`);
        } else if (this.route.snapshot.queryParams) {

          let queryParamsAux = '';

          // remove credential from params
          const { credential, ...queryParamsAuxWithoutCredential } = this.route.snapshot.queryParams;
          Object.keys(queryParamsAuxWithoutCredential).forEach((key, index) => {
            queryParamsAux += `${index === 0 ? '?' : '&'}${key}=${this.route.snapshot.queryParams[key]}`;
          });

          setTimeout(() => {
          this.updatePath(`${this.legacyUrl}/${legacyRoute}${'/' + parametros}${queryParamsAux}`);
          }, 100);
        }
      } else {

        setTimeout(() => {
          this.updatePath(`${this.legacyUrl}/${legacyRoute}${'/' + parametros}`);
        }, 100);
      }
    }
  }

  private updatePath(path) {
    this.path = path;
    if (path) {
      this.urlAuthRedirectService.currentIframeUrl = this.path;
    }
  }


  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription?.unsubscribe();
    }
  }

  cleanPath(path) {
    const temp2 = path.includes('?') ? path?.split('?')[0] : path;
    const temp3 = path.split('?')[1] ? path.split('?')[1].split('&').filter(a => { if (!a.includes('credential')) return a }) : null;
    const newPath = temp3 && temp3.length ? temp2 + '?' + temp3.reduce((a, b) => a + '&' + b) : temp2;

    return newPath;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {

    // keep current path on storage in case of a reload.
    // event.stopImmediatePropagation();
    let temp1 = this.urlAuthRedirectService.currentIframeUrl;
    if (temp1 && temp1.includes('?')) {
      temp1 = this.cleanPath(temp1);
    }

    sessionStorage.setItem('path', temp1);
    sessionStorage.setItem('angular-route', document.location.href);

    // event.returnValue = null;

  }


}
