import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileMenuCardComponent } from './profile-menu-card.component';
import { SharedModule } from '../../shared.module';
import { LanguageSwitcherModule } from '../language-switcher/language-switcher.module';



@NgModule({
  declarations: [ProfileMenuCardComponent],
  exports: [ProfileMenuCardComponent],
  imports: [
    CommonModule,
    SharedModule,
    LanguageSwitcherModule,
  ]
})
export class ProfileMenuCardModule { }
