import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { enumLoginTokenAction } from 'src/app/core/enums/enumLoginTokenAction';
import { AuthService } from 'src/app/core/services/auth.service';
import { MainPageService } from 'src/app/core/services/main-page.service';

@Injectable({
  providedIn: 'root'
})
export class SiaGuard  {

  constructor(
    private _authService: AuthService,
    private _mainPageService: MainPageService,
    private router: Router
  ) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const token = route.queryParams?.token;

    if (!token) {
      console.error('Um token precisa ser enviado');
      return this.router.navigate(['invalid-token']);
    }

    return this.loginSiaAndRedirect(token);

  }

  loginSiaAndRedirect(token) {
    return this._authService.signinWithSia(token).pipe(
      first(),
      map((next: any) => {
        if (next) {
          const returnUrl = this._mainPageService.getSiaReturnUrl(next.action as enumLoginTokenAction, token);
          this.router.navigate([returnUrl]);
          return false;
        }

        if (this._authService.isLoggedIn()) {
          this._authService.logout();
        }

        this.router.navigate(['invalid-token']);
        return false;
      }
      ),
      catchError(() => {
        if (this._authService.isLoggedIn()) {
          this._authService.logout();
        }
        this.router.navigate(['invalid-token']);
        throw new Error('Um token válido precisa ser enviado');
      })
    );
  }
}
