<app-sub-header [headerTitle]="'Avaliações' | translate" [border]="!hasEvaluationConfigModuleAccessPermission()">
  <ng-container *ngxPermissionsOnly="'EvaluationConfigModuleAccess'">
    <a *ngIf="configEvalTab?.isActive" cnet-filled-button color="primary" [routerLink]="[ './evaluation-config/new']">{{'Nova Avaliação' | translate}}</a>
  </ng-container>
</app-sub-header>

<ng-container  *ngIf="hasEvaluationConfigModuleAccessPermission()">
  <cnet-tab-group class="sub-header-tab">
    <div cnet-tab [tab-title]="'Pesquisar avaliações' | translate" [routerLink]="'./monitor'" routerLinkActive
      #rla="routerLinkActive" [active]="rla.isActive">
    </div>
    <cnet-tab [tab-title]="'Configurar Avaliações' | translate"
    [routerLink]="'./configure'" routerLinkActive
      #configEvalTab="routerLinkActive" [active]="configEvalTab.isActive">
    </cnet-tab>
  </cnet-tab-group>
</ng-container>


<router-outlet></router-outlet>
