import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StartupConfig } from 'src/app/core/models/startup-config.model';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoaderService } from 'src/app/core/services/shared/loader.service';
import { StartupService } from 'src/app/core/services/startup.service';
import { Role } from 'src/app/core/enums/role';
@Component({
  selector: 'app-profile-menu-card',
  templateUrl: './profile-menu-card.component.html',
  styleUrls: ['./profile-menu-card.component.scss']
})
export class ProfileMenuCardComponent implements OnInit {

  @Input() userDetails;

  private _routesProfileMenu
  @Input()
  get routesProfileMenu() {
    return this._routesProfileMenu
  };
  set routesProfileMenu(routesProfileMenu) {
    this._routesProfileMenu = routesProfileMenu.sort((a, b) =>  a.data.profileMenuItenOrder - b.data.profileMenuItenOrder );
  }

  @Input() menu;
  @Input() isMobile;

  @Output() logoutEvent: EventEmitter<boolean> = new EventEmitter();

  user: User;

  config;
  isUserRoleCnetAdmin = this._authService.getUserRole() === Role.CnetAdmin;
  items = Array.from({ length: 10000 }).map((_, i) => ({
    id: i,
    label: `Item #${i}`
  }));


  constructor(
    private _authService: AuthService,
    private _loaderService: LoaderService,
    private _startupService: StartupService,
    private _router: Router
  ) {
    this._startupService.getPortalConfig().subscribe((config: StartupConfig) => {
      this.config = config;
    });

    this._router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.user = this._authService.getUser();
      }
    });
  }

  ngOnInit(): void {
    this.user = this._authService.getUser();
  }

  hideMenu() {
    this.menu.hide();
  }

  showLanguageOption(){
    return this._startupService.showLanguageOption();
  }

  logout(): void {
    this.menu?.hide();
    setTimeout(() => {
      this._loaderService.forceHide();
      this._authService.logoutAndRedirectToLogin();
      this.logoutEvent.emit(true);
    }, 0.5);
  }

}
