import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentSignatureRequest } from 'src/app/core/models/document-signature-request';
import { EvaluationService } from '../evaluation.service';

@Injectable({
  providedIn: 'root'
})
export class EvaluationManualSignatureResolver  {

  constructor(
    private evaluationService: EvaluationService,
    private router: Router) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DocumentSignatureRequest> {
    return this.evaluationService.getEvaluationDocumentByEvaluationCode(route.params.evaluationCode)
      .pipe(map(data => {
        if (data) {
          return new DocumentSignatureRequest(data.evaluationDocumentCode, data.documentLocation)
        } else {
          this.router.navigate(['/not-found'])
          return null;
        }
      }
      ));
  }
}
