import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSwitcherComponent } from './language-switcher.component';
import { CnetNgxUiModule, CnetSelectModule } from '@cnet/ngx-ui';
import { SharedModule } from '../../shared.module';



@NgModule({
  declarations: [LanguageSwitcherComponent],
  exports: [LanguageSwitcherComponent],
  imports: [
    CommonModule,
    CnetNgxUiModule,
    SharedModule
  ]
})
export class LanguageSwitcherModule { }
