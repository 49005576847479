import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { MainPageService } from 'src/app/core/services/main-page.service';
import { IframeRenderedService } from '../pages/iframe-error/iframe-rendered.service';
/**
 * This guard works as an solution to login with credential
 * it was created to solve a url who redirects to a angular route like (/l/company/internshipscredential) and has a credential
 *  that way, i can login with the credential and proceed to the route
 * ex : => /l/company/internshipscredential=60g8Zh1VzeScr4Ym1OC5otZHMtbWGNI7ATeh7_j0Gr9Sam83QAILwAx8i57nnfUelBnkIg4ASQ7Br0UAl62gSo5eFT-EU2U7HIxgjJcmV1gDjb6_MaynFnaQ3tewqln90
 *
 */

@Injectable({
  providedIn: 'root'
})
export class LoginCredentialGuard  {
  constructor(
    private _authService: AuthService,
    private _mainpageService: MainPageService,
    private _router: Router,
    private _iframeRenderedService: IframeRenderedService
  ) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    if (this._iframeRenderedService.isAppRenderedinsideIframe()) {
      // caso esteja em um iframe
      this._router.navigate([`iframe-rendered`]);
      return false;

    } else {
      return await this.validate(next.queryParams.hasOwnProperty('credential') ? next.queryParams.credential : null);
    }
  }

  async validate(credential) {
    if (!this._authService.isLoggedIn() && credential) {
      const user = await this._authService.signinWithCredential(credential).toPromise().catch(() => {
        this._mainpageService.navigateToLoginUrl();
        return false;
      });
    }
    return true;
  }


}
