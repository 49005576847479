@if(details && details.title ){
<div class="d-flex flex-row gap-1 " [class.flex-column]="isMobile" [class.justify-content-center]="isMobile">

  @if(showPhoto){
  <div class="d-flex align-items-center" [class.justify-content-center]="isMobile">

    <cnet-avatar class="user-avatar" size="large" [src]="details.urlPhoto" [alt]="details.title"/>
  </div>
  }

  <div class="align-self-stretch d-flex flex-column extra-small-gap w-100" [class.text-center]="isMobile"
    [class.align-items-center]="isMobile">

    <h2 class="title m-0 text-break">
      @if(details.translateTitle){
      {{details.title | translate}}
      }@else {
      {{details.title}}
      }
    </h2>

    @if(details.subTitle){
    <p class="mb-1 text-break description">
      @if(details.translateSubTitle){
      {{details.subTitle | translate}}
      }@else {
      {{details.subTitle}}
      }
    </p>
    }

    @if(details.role){
    <p cnet-badge cnet-badge-type="stroked" cnet-badge-color="primary" class="fit-content m-0">
      {{details.role | permissionRoleId}}</p>
    }

    @if(showGroups && details.groups?.length){
    <div>
      @for(group of details.groups; track group){
      <p cnet-badge cnet-badge-type="stroked" cnet-badge-color="default" class="text-capitalize fit-content mr-1">
        {{group }}</p>
      }
    </div>

    }


  </div>

</div>

}@else {
<div class="d-flex flex-row align-items-center gap-1" [class.flex-column]="isMobile"
  [class.justify-content-center]="isMobile">
  <div *ngIf="showPhoto">
    <div cnet-avatar class="loading-avatar"></div>
  </div>
  <div class="align-self-stretch d-flex flex-column w-100 extra-small-gap" [class.align-items-center]="isMobile">
    <div class="m-0 w-100">
      <div class="loading w-100"></div>
    </div>
    <div class="loading-description w-50"></div>
    <div class="loading-description w-50"></div>
    <div class="loading-description w-50" *ngIf="showGroups"></div>
  </div>
</div>
}
