import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, scan } from 'rxjs/operators';

export interface Download {
  content: Blob | null;
  progress: number;
  state: "PENDING" | "IN_PROGRESS" | "DONE";
};

export enum enumDownloadState {
  "PENDING" = "PENDING",
  "IN_PROGRESS" = "IN_PROGRESS",
  "DONE" = "DONE"
}

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(
  event: HttpEvent<unknown>
): event is HttpProgressEvent {
  return (
    event.type === HttpEventType.DownloadProgress ||
    event.type === HttpEventType.UploadProgress
  );
};

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(
    private http: HttpClient
  ) {
  }

  download(url) {
    return this.http.get(url, { responseType: 'blob', reportProgress: true, observe: 'events' })
      .pipe(
        scan(
          (download: Download, event): Download => {
            if (isHttpProgressEvent(event)) {
              return {
                progress: event.total
                  ? Math.round((100 * event.loaded) / event.total)
                  : download.progress,
                state: "IN_PROGRESS",
                content: null
              };
            }
            if (isHttpResponse(event)) {
              return {
                progress: 100,
                state: "DONE",
                content: event.body
              };
            }
            return download;
          },
          { state: "PENDING", progress: 0, content: null }
        ),
        distinctUntilChanged((a, b) => a.state === b.state
          && a.progress === b.progress
          && a.content === b.content
        ),
        catchError(err => {
          return throwError(err);
        })
      )
  }
  downloadReturnBlob(url) {
    return this.http.get(url, { responseType: 'blob' })
  }
  uploadReturnBlob(url, formData) {
    return this.http.post<any>(url, formData, { observe: 'response', responseType: 'json' });
  }

  convertBaseb64ToBlob(b64Data, mimeType): Blob {
    mimeType = mimeType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: mimeType });
  }

  downloadReturnBlobPost(url, body) {
    return this.http.post(url, body, { responseType: 'blob' as 'json' })
  }

  getBlobContent(blob): Observable<any> {
    const text = new Response(blob).text()
    return from(text)
  }

  getBlobAsUrl(blob) {
    return URL.createObjectURL(blob)
  }

  fileToBase64(file: File | Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error("Failed to read the file as base64."));
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }


}
