import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { IdleHandlerService } from '../services/idle-handler.service';
import { StartupService } from '../services/startup.service';
import { LanguageService } from '../services/language.service';

@Injectable({
  providedIn: 'root'
})
export class StartupGuard  {

  startup = inject(StartupService)
  router = inject(Router)
  idleHandlerService = inject(IdleHandlerService)
  languageService = inject(LanguageService)



  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    return this.initFunction();
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    return this.initFunction();
  }


  initFunction(): Observable<boolean> | boolean {

    return this.startup.init().pipe(
      map(config => {
        this.idleHandlerService.start();
        this.languageService.setInitialLanguage();
        return true
      }),

      catchError((err: HttpErrorResponse) => {
        if (err.status === HttpStatusCode.NotFound) {
          window.open('http://symplicity.com/br', '_self');
        } else {
          this.router.navigate([`maintenance`]);
        }
        return of(false);
      })
    )
  }


}
