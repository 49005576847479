import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CredentialService {

  public credential: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {
    this.credential = new BehaviorSubject('');
  }

  getCredential(): Observable<string> {
    return this.credential.asObservable();
  }

  public updateCredential(credential: string) {
      this.credential.next(credential);
  }
}
